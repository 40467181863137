body {
  background-color: $background;
  font-family: 'Inter', sans-serif;
}

.form_search-date-wrapper{
  height: 100%;
  position: relative;

  & > div {
    height: 100%;
    display: flex;
  }

  .react-datepicker__calendar-icon {
    position: absolute;
    padding: 0;
    width: unset;
    height: 40%;
    z-index: 1;
    top: 50% !important;
    right: 15px !important;
    left: unset !important;
    transform: translateY(-50%);
    pointer-events: none;
    fill: $dark_neutral;
  }

  & + .react-datepicker__tab-loop .react-datepicker-time__input input{
    padding: 0;
    min-width: unset;
  }
}

.edit-form {
  .input-field {
    color: $black;
    height: 35px;
    border-radius: 5px;
    border: 2px solid #9de79d;
    background-color: #ccf3cc;
    margin-right: 32px;
    margin-left: 32px;
    margin-bottom: 15px;
    font-size: 12px;
    text-align: left;
    padding-left: 15px;
  }

  .input-field:focus {
    outline: none;
  }

  .btn-form {
    border-radius: 5px;
    border: none;
    font-size: $font-txt;
    color: black;
    height: 30px;
  }

  .close-form {
    background-color: gainsboro;
    width: 15%;
  }

  .save-form {
    background-color: $green;
    width: 20%;
  }
}

tbody td {
  font-size: 13px;
}


.dps-content-area {
  height: 100%;
  width: 100%;
  min-width: 590px;
  position: relative;
  display: flex;
  flex-direction: column;

  .main-title {
    width: 100%;
  }

  .title-plan{
    h1{
      flex: 0 0 max-content;
      margin-right: 52px;
    }

    .btn-add-plan{
      border: 0;
      background: transparent;
      display: flex;
      align-items: center;
      margin-right: auto;
      font: 400 14px 'Inter', sans-serif;

      svg {
        margin-right: 10px;
        margin-top: -1px;
      }
    }

    .import-plan{
      margin-right: 30px;
      padding-right: 30px;
      border-right: 2px solid $in_border;

      button{
        padding: 2px 16px;
        border: 1px solid $in_border;
        background: transparent;
        border-radius: 5px;
        font: 700 12px 'Inter', sans-serif;
        color: $label;
      }
    }

    @media (max-width: 900px) {
     .import-plan{
      margin-right: 20px;
      padding-right: 20px;
      button{
        padding: 2px 3px;
      }
     }
    }
  }
}

.dps-main-content {
  width: 100%;
  flex: 1;
  padding: 0;
  position: relative;
  overflow-y: auto;
  overflow-x: hidden;

  .dps-main-content-area {
    width: 100%;
    height: 100%;
    // background-color: $c-white;
    display: flex;
  }
}

.dps-main-content-setting{
  border: $card-border;
  border-radius: 15px;
  overflow: hidden;
  box-shadow: $card-shadow;
  padding: 0;

  .dps-main-content-area {
    width: 100%;
    height: 100%;
    background-color: $c-white;
    display: flex;
  }
}

.dps-table {
  height: auto;
  max-height: 100%;
  display: flex;
  flex-direction: column;
  background-color: $c-white;
  border-radius: 10px !important;
  box-sizing: border-box;
  flex: unset !important;
  overflow: hidden;
  border: $card-border;
  box-shadow: $card-shadow;
  table-layout: fixed;

  .table_sort{
    .t-sort{
      display: flex;
      justify-content: flex-start;
      align-items: center;
      white-space: nowrap;
    }
    
    span{
      display: inline-block !important;
      background: unset !important;
      width: unset !important;
      height: unset !important;
      padding: 0 !important;

      svg{
        color: $secondary;
        margin: 0 4px !important;
        vertical-align: middle;
      }
    }
  }

  td {
    overflow: hidden;
    text-overflow: ellipsis;
    padding:20px;
    
    p {
      font: 400 12px 'Inter', sans-serif;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      margin-bottom: 0;
    }

    &:last-child{
      text-align: center;
    }

    span{
      font: 400 12px 'Inter', sans-serif;
    }
  }

  tr td:last-child {
    text-align: right;
  }

  .schedule-table-header {
    td:last-child {
      float: revert;
    }
  }

  .td-not-ellipsis {
    overflow: revert;
    text-overflow: revert;
  }

  tbody {
    display: block;
    overflow: auto;
    
    tr:last-child{
      border-bottom: 0;
    }

    tr:hover td {
      background-color: $background-hover;
    }

    td{
      padding: 20px;
      font: 700 12px 'Inter', sans-serif;
      white-space: nowrap;
      transition: 0.2s ease;

      &:nth-child(1){
        width: 60px;
      }
      &:nth-child(3){
        width: 100px;
      }
    }
  }

  thead, tbody {
    border-spacing: 0px;

    td{
      white-space: nowrap;
    }
  }

  thead, tbody tr {
    display: table;
    width: 100%;
    table-layout: fixed; /* even columns width , fix width of table too*/
    border-bottom: 1px solid $border;
  }

  thead td {
    padding: 10px 20px;
    color: $c-black;
    font: 400 11px 'Inter', sans-serif;

    &:nth-child(1) {
      width: 60px;
    }
    &:nth-child(3) {
      width: 100px;
    }
  }

  thead tr {
    background: #FCFCFC;
  }

  .active {
    background-color: $c-yellow !important;
  }

  .btn-small {
    width: 30px;
    height: 30px;
    border-radius: 20px;
    border: none;
    display: inline-flex;
    flex-direction: row;
    align-items: center;
    margin: 5px;
    flex-shrink: 0;

    svg {
      margin: 0 auto;
    }
  }

  .edit {
    background-color: $c-black;
  }

  .natual {
    background-color: $c-gray;
  }

  .delete {
    background-color: transparent;

    svg{
      width: 14px;
    }
  }
}

.dps-list-f {
  height: fit-content;
  max-height: 100%;
  display: flex;
  flex-direction: column;
  background-color: $c-white;
  border-radius: 10px !important;
  box-sizing: border-box;
  flex: unset !important;
  overflow: hidden;
  border: $card-border;
  box-shadow: $card-shadow;
  .header {
    display: flex;
    align-items: center;
    min-height: 41px;
    background: #FAFAF9;
    border-bottom: 1px solid #EBEBEB;
    font-size: 11px;
  }
  .list_sort{
    .l-sort{
      display: flex;
      justify-content: flex-start;
      align-items: center;
      white-space: nowrap;
    }
    span{
      display: inline-block !important;
      line-height: 9px;
      background: unset !important;
      width: unset !important;
      height: unset !important;
      padding: 0 !important;
      svg{
        color: $secondary;
        margin: 0 4px !important;
        vertical-align: bottom;
      }
    }
  }
  .body {
    overflow-y: auto;
    .list-item {
      display: flex;
      align-items: center;
      height: 61px;
      font-size: 12px;
      border-bottom: 1px solid #EBEBEB;
      cursor: pointer;
      &:last-child {
        border-bottom: none;
      }
      &:hover {
        background-color: $background-hover;;
      }
      transition: 0.2s ease;
    }
  }
  .clmn {
    margin-left: 20px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
}

.add-item {
  width: 50%;
  height: auto;
  border-radius: 3px;
  margin-bottom: 30px;
  display: flex;
  position: relative;

  input[type="checkbox"] {
    width: auto;
    margin-right: 8px;
  }

  .btn-save, .btn-upload, .btn-add {
    width: max-content;
    height: 40px;
    border-radius: unset;
    border: none;
    display: inline-flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    background-color: $dark_neutral;
    color: $white;
    padding: 14px 16px;
    white-space: nowrap;
    font: 700 14px 'Inter', sans-serif;

    svg {
      margin: 0 auto;
    }
  }

  .btn-save {
    align-self: flex-end;
    border-top-right-radius: inherit;
    border-bottom-right-radius: inherit;
  }

  .btn-save[disabled] {
    background-color: $c-gray;
  }

  .btn-upload {
    background-color: $c-black;
  }

  div{
    flex: 1 1;
  }

  Input {
    color: $black;
    text-align: left;
    width: 100%;
    padding: 14px 12px;
    background: transparent !important;
    border: 1px solid $border;
    border-right: 0;
    font: 400 16px 'Inter', sans-serif;
  }

  .small {
    width: 50px;
  }

  .large {
    width: 200px;
  }
}

// Search Table
.search-table{
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  padding-bottom: 20px;

  .form_search{
    display: flex;
    flex-wrap: wrap;

    .form_search-item {
      width: 150px;
      margin-right: 20px;
      display: flex;
      flex-direction: column;
      margin-bottom: 10px;
      position: relative;
      padding-top: 20px;

      label {
        font-size: 12px;
        position: absolute;
        top: 0;
        left: 0;
      }

      input, select{
        max-width: 100%;
        padding: 6px 6px 6px 12px;
        border: $card-border;
        border-radius: 5px;
        font-size: 12px;
      }

      .mask-select{
        height: unset;
        border: $card-border;

        select{
          min-width: unset;
          width: 100%;
          height: unset;
          padding-right: 24px;
          background: $white;
          border: 0;
          color: inherit;
        }

        svg {
          left: unset;
          right: 10px;
        }
      }

      .form_search-date{
        height: 100%;
        display: flex;
        align-items: center;
        background: $white;
        border: $card-border;
        border-radius: 5px;
        overflow: hidden;

        input{
          border: 0;
          outline: 0;
          position: relative;
          left: 0;
          top: 0;
          width: max-content;
          height: 100%;
          cursor: pointer;
        }
      }

      .error-message{
        position: absolute;
        top: 100%;
        width: max-content;
        font-size: 12px;
        color: $danger;
        margin-left: 12px;
      }
    }

    & > button {
      border: $card-border;
      background: $dark_neutral;
      color: $white;
      padding: 6px 12px;
      border-radius: 5px;
      font-size: 13px;
      text-transform: capitalize;
      align-self: flex-start;
      margin-top: 20px;
    }
  }
}

.dps-content-tablet .search-table{
  padding: 20px;
}

.form_search-mobile{
  display: flex !important;
  align-items: center;

  .search-table{
    flex-direction: column;
    align-items: flex-start;
    background: $white;
    transition: height 0.25s linear;
  
    & + .dps-main-content{
      padding: 0;
    }
  
    .form_search {
      width: 100%;
      gap: 20px;
  
      .form_search-item{
        margin: 0;
        width: calc(50% - 10px);
  
        .error-message{
          margin: 0 0 0 12px;
          padding: 0;
          background: transparent;
        }
      }
    }
  
    button{
      margin-top: 20px;
    }
  }
} 

// End customer Search Table

.wrap_table{
  width: 100%;
  flex: 1 !important;
  flex-basis: 0 !important;
  overflow: auto;
}


// Component Table
.abc h1{
  color: red;
}

.check-flight-table-mobile {
  display: none;

}