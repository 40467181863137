// Iport font-family
@font-face {
  font-family: "Inter";
  src: url("../font/Inter-VariableFont_slnt\,wght.ttf");
}

/*Variabales*/
//@import "~bootstrap/scss/bootstrap";
@import "variabales";

/* page*/
@import "login";
@import "menu";
@import "table";
@import "home";
@import "dronestatus";
@import "flightplan";
@import "planMap";
@import "inspectionresult.scss";
@import "mobile.scss";
@import "schedule.scss";
@import "notification.scss";
@import "admin.scss";
@import "scan.scss";
@import "privacy_support.scss";
@import "form-actions-utils";
@import "drone-detail-status";
@import "resize_panel";
@import "kiriha-mode";
@import "setting";

*{
  font-family: 'Inter', sans-serif;
}

button{
  white-space: nowrap;
}

div[hidden],
tr[hidden] {
  display: none !important;
}

.hidden {
  display: none !important;
}

.videoContent {
  display: flex;
  flex: 1;
  width: 100%;
  video {
    height: auto;
    width: 90%;
  }
}

.bold {
  font-weight: bold !important;
}

.loading {
  flex: 1;
  background-color: $c-white;
  border-radius: 10px;
  display: flex;
  align-items: center;
  height: 100%;
  width: 100%;
  img {
    margin: 0 auto;
  }
}

.btn-small,
button,
.btn-upload,
.btn-add {
  cursor: pointer;
}
.textAva {
  margin: 0 10px;
}

.header-notice {
  display: flex;
  flex-direction: column;
  align-self: center;
  td {
    width: 60% !important;
    margin: 0 auto;
  }
}

.dps-modal-dialog {
  position: fixed;
  width: 100%;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.4);
  z-index: 9999;
  display: flex;
  flex-direction: row;
  align-items: center;
}

.dps-modal-content {
  width: 500px;
  height: auto;
  margin: 0 auto;
  background-color: $c-white;
  border-radius: 10px;
}

.dps-modal-body {
  padding: 20px;
}

.dps-client-email-management {
  width: 100%;
  height: 100%;
  .dps-table {
    display: flex;
    height: 100%;
    flex-direction: column;
    tbody {
      flex: 1;
      overflow: auto;
    }
  }
  .dps-setting {
    margin: 0px;
    height: 100%;
    .txt-notice {
      margin: 10px 0 0 0;
    }
    .add-item {
      border-radius: 0 0 10px 10px;
      margin-top: auto;
    }
  }
}

.modal-footer {
  height: 60px;
  // padding: 10px 20px;
  display: flex;
  flex-direction: row;
  align-items: center;
  button {
    margin-left: auto;
  }
}

.modal-header {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 10px 20px;
  border-bottom: 1px solid $c-gray-light;

  .h4 {
    flex: 1;
  }

  .close {
    width: 30px;
    height: 30px;
    border-radius: 50%;
    padding: 0px;
    border: 0px;
    background-color: $c-red;

    span {
      color: $c-white;
      line-height: 30px;
      font-size: 20px;
      text-align: center;
      display: block;
    }

    .sr-only {
      display: none;
    }
  }
}

#root {
  .dps-menu {
    width: 190px;
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    z-index: 1500;
    border-radius: 0;
    overflow: hidden;

    .menu-item {
      cursor: pointer;
    }

    .dps-menu-tablet {
      // width: 200px;
      transition: width 0.5s;
      width: 250px;
      left: 0;
      top: 0;
      bottom: 0;
      // border-radius: 0;
      border-top-left-radius: 0px;
      border-bottom-left-radius: 0px;
      .tab-menu {
        overflow: hidden;
        .mheader,
        .mfooter {
          width: 250px;
        }
      }
    }

    .dps-menu-not-show {
      width: 0px;
      .tab-menu {
        div {
          overflow: hidden;
        }
        .choose-btn {
          visibility: hidden;
        }
      }
    }
  }


  .dps-menu-hamburger-button {
    position: absolute;
    display: none;
    padding: 16.5px 17px;
    // background-color: #3e3a39;
    background-color: #ffffff;
    border-top-right-radius: 10px;
    border-bottom-left-radius: 10px;
    z-index: 1000;

    .dps-menu-hamburger-button-tablet {
      display: block;
      position: absolute;
      top: 0px;
      left: 190px;
      transition: left 0.5s;
    }

    .dps-menu-hamburger-button-not-show {
      left: 0px;
      transition: left 0.5s;
    }
  }

  .dps-menu-back-ground {
    position: fixed;
    width: 100%;
    height: 100%;
    background-color: #000000;
    opacity: 0.5;
    z-index: 500;
  }

  .dps-content {
    width: calc(100% - 190px);
    height: 100%;
    min-height: 100%;
    padding: 12px;
    position: fixed;
    top: 0;
    bottom: 0;
    left: 190px;
    right: 0;
    box-sizing: border-box;
    z-index: 1000;

    .flight-plan-button-back {
      display: none;
    }

    .dps-content-tablet {
      padding: 0;

      #root {
        .dps-menu {
          width: 250px;
        }
      }

      .main-title {
        height: 60px;
        min-height: 60px;
        margin-bottom: 0;
        background-color: #ffffff;
      }
    
      @media (max-width: 1181px) {
        .main-title {
          padding: 12px;
        }
      }

      .dps-main-content {
        padding: 30px 20px 20px 20px;
        height: calc(100% - 60px);

        .drone-list{
          width: calc(100% + 20px);
        }
      }

      .dps-title h1 {
        padding-left: 50px;   
        padding-top: 0;
        text-align: start;
      }

      .flight-plan-area {
        width: calc(100vw - 40px);
        .dps-table,
        .add-item {
          width: calc(100vw - 40px);
        }
      }

      .language-switch {
        padding-right: 20px;
      }

      .flight-plan-button-back {
        display: block;
        position: absolute;
        right: 0;
        top: 0;
        width: auto;
        height: auto;
        padding: 5px 1rem;
        margin-bottom: 10px;
        background-color: $c_yellow;
        border: 0px solid $c_yellow;
        border-radius: 5px;
      }
    }

    .total-list{
      margin-top: 14px;
      margin-left: 3px;
      font: 400 11px 'Inter', sans-serif;
    }
  }

  .center {
    text-align: center;
    margin: 10px auto;
  }

  .bold {
    font-weight: bold;
  }
}
.form-profile {
  .item {
    display: flex;
    flex-direction: row;
    align-items: center;

    align-self: stretch;
    label {
      width: 100px;
    }
    div {
      flex: 1;
      position: relative;
    }
  }
}

.edit-user {
  .item {
    display: flex;
    flex-direction: row;
    align-items: center;

    margin: 15px 0;
    align-self: stretch;
    label {
      width: 150px;
    }
    div {
      position: relative;
    }
    .icon_folder{
      padding: 0;
      border: 0;
      background: transparent;
      margin-left: auto;
      
      img{
        height: 20px;
      }
    }
  }
  
  Input, .mash_input {
    height: 30px;
    border-radius: 5px;
    border: $card-border;
    background-color: transparent;
    color: $black;
    text-align: left;
    padding-left: 12px;
    width: 280px;
  }
  .btn-save {
    width: 40px;
    height: 40px;
    background-color: $c-green;
    border-radius: 50%;
    border: 0;
    align-self: flex-end;
    margin-top: 20px;
  }
  .btn-save[disabled] {
    background-color: #ddd;
    cursor: not-allowed;
    &:before {
      background-color: #ddd;
      cursor: not-allowed;
    }
  }
}

//START

button:focus {
  outline: none;
  box-shadow: none;
}
//tabs
.dps-tabs {
  width: 200px;
  min-width: 200px;
  padding: 20px;
  display: inline-block;
  border-right: 1px solid $c-gray-light;
  a {
    display: block;
    height: 40px;
    text-decoration: none;
    color: $c-black;
    padding: 0 10px;
    font: 700 12px 'Inter', sans-serif;
    line-height: 40px;
    white-space: nowrap;
  }
  .active {
    background-color: $border;
    border-radius: 5px;
  }
}
.margin-0 {
  margin: 0px;
}
.dps-tabs-content {
  flex: 1;
  height: 100%;
  padding: 30px;
  // max-height: 450px;
}
.table-setting {
  flex: 1;
  flex-direction: column;
  display: flex;
  width: 100%;
  min-height: 0;
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;

  .clmn {
    &:nth-child(1){
      flex: 0 1 60px;
    }
    &:nth-child(2){
      flex: 1 1 200px;
    }
    &:nth-child(3){
      flex: 1 1 100px;
    }
    &:nth-child(4){
      flex: 1 1 100px;
    }
    &:nth-child(5),
    &:nth-child(6),
    &:nth-child(7){
      flex: 1 1 200px;
    }
    &:last-child{
      flex: 0 1 150px;
      text-align: center;
    }
  }
  .actions .btn-small{
    align-items: center;
    border: none;
    border-radius: 20px;
    display: inline-flex;
    flex-direction: row;
    flex-shrink: 0;
    height: 30px;
    margin: 5px;
    width: 30px;
    background: transparent;

    .fix-icon-center-ios svg{
      width: 14px;
    }
  }
}
// admin setting
.admin-setting {
  height: 100%;
  display: inline-block;
  padding: 20px;
  .dps-input {
    display: inline-block;
    border: 2px solid #9de79d;
    max-width: 400px;
  }
  .form-profile {
    display: flex;
    flex-direction: column;
    .item {
      display: flex;
      flex-direction: row;
      margin-bottom: 30px;
      p {
        width: 100px;
      }
    }
    .dps-button {
      width: 150px;
      border-radius: 10px;
      background-color: $c-green;
      padding: 0px;
    }
  }
  .form-icon {
    display: flex;
    flex-direction: column;
    p {
      margin: 0px;
    }

    .textAva{
      margin-left: 0;
      font: 400 12px 'Inter', sans-serif;
    }

    .dps-button {
      height: auto;
      min-height: unset;
      padding: 5px;
      background-color: $c-green;
      margin-top: 10px;
      color: $white;
      border-radius: 3px;

      span{
        color: currentColor;
        font: 700 12px 'Inter', sans-serif;
      }
    }
    .dps-button[disabled] {
      color: $tertiary;
      background: unset;
    }
    .list-icon {
      display: flex;
      flex-direction: row;
      align-items: center;
    }
    .item {
      align-items: center;
      margin-right: 20px;

      input {
        display: none;
      }
      display: flex;
      flex-direction: column;

      .preview-image {
        margin-top: 8px;
        width: 150px;
        cursor: pointer;
        height: 150px;
        border-radius: 50%;
        background: $dark_neutral;
        border: 1px solid $c-gray-light;
        display: flex;
        align-items: center;
        justify-content: center;
        position: relative;
        overflow: hidden;

        &:hover {
          background-color: $c-gray-light;
          p {
            display: flex;
            align-items: center;
            justify-content: center;
            height: 100%;
          }
        }
        img {
          max-width: 100%;
          max-height: 100%;
          object-fit: cover;
        }
        p {
          position: absolute;
          top: 0;
          right: 0;
          width: 100%;
          color: $c-yellow;
          display: none;
        }
      }
    }
  }
}

// Corporation Admin setting
.dps-setting {
  width: 100%;
  height: 100%;
  position: relative;
}

.txt-notice {
  text-align: center;
  margin: 0px;
}

//END

.inline-edit {
  display: flex;
  flex-direction: row;
  align-items: center;
  .value-content {
    margin-right: 100px;
  }
  form {
    display: flex;
    flex-direction: row;
    align-items: center;
    .save {
      background-color: $c-black;
    }
  }
  .value-content {
    flex: 1;
    display: block;
  }
}

.dps-title {
  display: flex;
  flex-direction: row;
  // flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  min-height: 40px;
  margin-bottom: 20px;
  max-height: 60px;
  h1 {
    flex: 1;
    min-width: 90px;
    font: 700 20px 'Inter', sans-serif;
    margin-bottom: 0;
  }
  .toggle, .delete-dowload-compare {
    align-self: center;
    margin-right: 30px;
    padding-right: 30px;
    border-right: 2px solid $in_border;
  }
  .detail_setting_mode {
    align-items: center;
    margin-right: 10px;
    padding-right: 10px;
    border-right: 2px solid $in_border;
  }

  .user-option{
    display: flex;
    justify-content: flex-end;
    align-items: center;
    position: relative;

    .btn-setting{
      background: unset;
      border: 0;
      margin: 0 7px;
    }

    .textAva{
      font-size: 11px;
      font: 400 12px 'Inter', sans-serif;
      max-width: 150px;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }

    .image{
      width: 30px;
      height: 30px;
      border-radius: 50%;
      background: $dark_neutral;
      object-fit: cover;
    }
  }
  .title-group-action {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    align-items: center;
  }
}


.check_is_tablet {
  @media(max-width: 1181px) {
    .dps-title {
      max-height: 60px;
    }
    .dps-title .user-option .btn-setting {
      padding-bottom: 3px;
  }
  }
}

.marker {
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer !important;
}

.marker-item {
  position: relative;

  .device_tracking-marker{
    width: max-content;
    position: absolute;
    border: 1px solid $white;
    border-radius: 50%;

    svg{
      display: block;
      width: 8px;
      height: 8px;
      position: relative;
    }

    .flight-next{
      width: max-content;
      position: absolute;
      top: 110%;
      left: -6px;
      transform: rotate(180deg);

      svg{
        width: 20px;
        height: 20px;
      }

      span{
        position: absolute;
        top: 45%;
        left: 50%;
        transform: translate(-50%, -50%);
        font: 700 10px 'Inter', sans-serif;
      }
    }

    .capture{
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);

      svg{
        color: $dark_neutral;
        width: 6px;
        height: 6px;
      }
    }

    &:hover .flight-next{
      display: block !important;
    }
  }

  .takeoff, .landing, .charging-marker {
    border: 1px solid #fff;
    border-radius: 50%;

    span{
      position: absolute;
      top: 45%;
      left: 50%;
      transform: translate(-50%, -50%);

      svg{
        width: 4px;
        height: 3.46px;
      }
    }
  }

  .charging-marker span svg{
    color: #fcd13e;
  }

  .takeoff{
    color: $primary;

    span {
      color: $dark_neutral;
    }
  }
  .pending, .landing{
    color: $dark_neutral;
    transform: rotate(180deg);
    span {
      color: $primary;
    }
  }
  .pass{
    color: $primary;

    .flight-next{
      top: unset;
      left: -6px;
      bottom: 110%;
      transform: unset;
    }
  }

  &:hover {
    .popup {
      visibility: visible;
    }
  }

  .popup {
    visibility: hidden;
    position: absolute;
    display: flex;
    flex-direction: row;
    z-index: 1;
    bottom: 50px;
    width: 160px;
    left: -60px;

    background-color: white;
    border-radius: 10px;
    padding: 5px 15px;
    border: 1px solid rgba(0, 0, 0, 0.1);
    .info {
      flex: 1;
    }

    .link {
      background-color: $c-green;
      width: 30px;
      height: 30px;
      border-radius: 15px;
      display: flex;
      align-items: center;
      justify-content: center;
      margin-left: 15px;
      cursor: pointer;
    }
    &:after {
      content: " ";
      position: absolute;
      top: 100%; /* At the bottom of the tooltip */
      left: 50%;
      margin-left: -5px;
      border-width: 5px;
      border-style: solid;
      border-color: white transparent transparent transparent;
    }

    p {
      margin: 0;
      display: block;
      font-weight: bold;
    }
  }
}

.marker-pink-pulse-mobile{
  position: absolute;
  top: 0;
  left: 0;
}

.pinkBg {
  background-color: #ed184f !important;
  background-image: linear-gradient(90deg, #ed184f, #fd5581);
}
.intro-banner-vdo-play-btn {
  height: 12px;
  width: 12px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 100rem;
  z-index: 1;
  position: relative;
  background: $danger;
  border: solid 1px #ffffff;
  flex-shrink: 0;

  & > svg {
    position: absolute;
    left: 50%; 
    transform-origin: 0 50%;
    width: 20px;
    height: 12px;
  }
}
.intro-banner-vdo-play-btn--small {
  height: 20px;
  width: 20px;
  display: block;
  text-align: center;
  border-radius: 50%;
  z-index: 1;
  -webkit-animation: ripples 1.8s infinite;
  animation: ripples 1.8s infinite;
}

.icon-arrow-drone-fly {
  width: 20px;
  height: 20px;
  svg {
    width: 20px;
    height: 20px;
    vertical-align: unset;
  }
}

.intro-banner-vdo-play-btn .ripple {
  position: absolute;
  width: 28px;
  height: 28px;
  z-index: -1;
  top: 0;
  left: 0;
  left: 50%;
  top: 50%;
  opacity: 0;
  transform: translate(-50%, -50%);
  border-radius: 100rem;
  -webkit-animation: ripple 1.8s infinite;
  animation: ripple 1.8s infinite;
}
.intro-banner-vdo-play-btn--small .ripple--small {
  position: absolute;
  width: 40px;
  height: 40px;
  z-index: -1;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  opacity: 0;
  border-radius: 50%;
  -webkit-animation: ripple 1.8s infinite;
  animation: ripple 1.8s infinite;
}

@-webkit-keyframes ripples {
  0% {
    opacity: 0;
  }
  25% {
    opacity: 0.1;
  }
  100% {
    opacity: 1;
  }
}
@keyframes ripples {
  0% {
    opacity: 0;
  }
  25% {
    opacity: 0.1;
  }
  100% {
    opacity: 1;
  }
}

@-webkit-keyframes ripple {
  0% {
    opacity: 0.5;
    -webkit-transform: translate(-50%, -50%) scale(0.5);
    transform: translate(-50%, -50%) scale(0.5);
  }
  100% {
    opacity: 0;
    -webkit-transform: translate(-50%, -50%) scale(1);
    transform: translate(-50%, -50%) scale(1);
  }
}
@keyframes ripple {
  0% {
    opacity: 0.5;
    -webkit-transform: translate(-50%, -50%) scale(0.5);
    transform: translate(-50%, -50%) scale(0.5);
  }
  100% {
    opacity: 0;
    -webkit-transform: translate(-50%, -50%) scale(1);
    transform: translate(-50%, -50%) scale(1);
  }
}
.intro-banner-vdo-play-btn .ripple:nth-child(2) {
  animation-delay: 0.3s;
  -webkit-animation-delay: 0.3s;
}
.intro-banner-vdo-play-btn .ripple:nth-child(3) {
  animation-delay: 0.6s;
  -webkit-animation-delay: 0.6s;
}

.map-editor {
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  position: relative;
  overflow: hidden;

  .dps-button_tab{
    border: 0;
    background: transparent;
    padding: 0 16px;
    font: 700 12px 'Inter', sans-serif;
  }
  .dps-button_tab.active{
    color: $tertiary;
  }
  .dps-button_tab.pass{
    color: #B0B0B0;
  }
  .menu-zone {
    display: flex;
    align-items: center;
    background-color: transparent;
    padding: 10px;
    width: 100%;
    border-bottom: 1px solid $in_border;

    .action {
      width: 100%;
      display: flex;
      justify-content: center;
      align-items: center;

      svg{
        color: $in_border;
      }
    }
  }
  .map-zone {
    width: 100%;
    flex: 1 0;
    display: flex;
    overflow: hidden;
    padding: 45px 60px;
    .loading {
      position: absolute;
      top: 50%;
      transform: translate(0, -50%);
      width: 100%;
      height: 100%;
    }
  }

  .button-area {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    .dps-button {
      width: 160px;
      background-color: $c-green;
      padding: 0 40px;
    }
    .dps-button[disabled] {
      background-color: $bg-gray;
    }
  }

  .image-map-area {
    position: relative;
    width: auto;
    height: 100%;
  }

  .upload {
    flex-grow: 0;
    flex-shrink: 1;
    width: 80px;
    flex-basis: 80px;
    min-width: 80px;
    border-left: 2px solid $c-gray;
    margin-left: 5px;
    cursor: pointer;
    svg {
      margin: 0 auto;
      display: block;
    }
    p {
      font-size: 10px;
      color: red;
      text-align: center;
    }
  }

  .marker-list::-webkit-scrollbar-track
  {
    border-radius: 10px;
    background-color: #F5F5F5;
  }

  .marker-list::-webkit-scrollbar
  {
    height: 8px !important;
    border-radius: 0.3em;
    background-color: #F5F5F5;
  }

  .marker-list::-webkit-scrollbar-thumb
  {
    border-radius: 10px;
    background-color: #fcd33c;
  }

  .marker-list {
    display: flex;
    flex-grow: 1;
    flex-shrink: 0;
    flex-basis: calc(100% - 80px);
    flex-direction: row;
    overflow-x: auto;
    overflow-y: hidden;
    padding-bottom: 5px;


    .item {
      width: 40px;
      height: 40px;
      min-width: 40px;
      background-color: $c-black;
      border-radius: 20px;
      margin: 0 10px;
      p {
        line-height: 40px;
        width: 100%;
        color: $c-white;
        margin: 0 auto;
        text-align: center;
      }
    }
  }
}

.toggle-label {
  font-size: 13px;
  padding-right: 20px;
  color: $c-black;
}
.toggle-switch {
  position: relative;
  width: 75px;
  display: inline-block;
  vertical-align: middle;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  text-align: left;
  .toggle-switch-checkbox {
    display: none;
  }
  .toggle-switch-label {
    display: block;
    overflow: hidden;
    cursor: pointer;
    border: 0 solid #bbb;
    border-radius: 20px;
    margin: 0;
  }
  .toggle-switch-inner {
    display: block;
    width: 200%;
    margin-left: -100%;
    transition: margin 0.3s ease-in 0s;
    &:before,
    &:after {
      display: block;
      float: left;
      width: 50%;
      height: 30px;
      padding: 0;
      line-height: 30px;
      font-size: 14px;
      color: white;
      font-weight: bold;
      box-sizing: border-box;
    }
    &:before {
      content: "ON";
      text-transform: uppercase;
      padding-left: 10px;
      background-color: $tertiary;
      color: #fff;
    }
  }
  .toggle-switch-disabled {
    background-color: #ddd;
    cursor: not-allowed;
    &:before {
      background-color: #ddd;
      cursor: not-allowed;
    }
  }
  .toggle-switch-inner:after {
    content: "OFF";
    text-transform: uppercase;
    padding-right: 10px;
    background-color: #bbb;
    color: #fff;
    text-align: right;
  }
  .toggle-switch-switch {
    display: block;
    width: 20px;
    margin: 5px;
    background: #fff;
    position: absolute;
    top: 0;
    bottom: 0;
    right: 40px;
    border: 0 solid #bbb;
    border-radius: 20px;
    transition: all 0.3s ease-in 0s;
  }
  .toggle-switch-checkbox:checked + .toggle-switch-label {
    .toggle-switch-inner {
      margin-left: 0;
    }
    .toggle-switch-switch {
      right: 0px;
    }
  }
}
@media (max-width: 768px) {
  .radio-switch {
    .flex-column {
      label {
        font-size: 15px !important;
      }
    }
  }
}

.drone-tracking-parent-hidden {
  display: none;
}

.popups {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  background-color: rgba(0, 0, 0, 0.4);
  padding: 0 40px;
  border-radius: 10px;
  width: 50px;
  margin-top: 3px;

  .info p {
    color: white;
    margin: 0px;
    line-height: 30px;
    font-size: 8px;
  }
  .link {
    margin-left: 5px;
    width: 20px;
    height: 26px;
    border-radius: 30px;
    background-color: transparent;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}

.react-confirm-alert-overlay {
  background-color: rgba(0, 0, 0, 0.4) !important;
  z-index: 9999 !important;
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  display: flex;
  align-items: center;
  justify-content: center;

  .react-confirm-alert {
    background-color: white;
    border-radius: 12px;
    padding: 20px;
  }
  h1 {
    text-align: center;
    color: $dark_neutral;
    margin-bottom: 11px;
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: 63px;
    font-weight: 400;
    font-size: 14px;
    font-family: 'Hiragino Sans';
  }

  .react-confirm-alert-button-group {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: stretch;
    gap: 20px;
    button {
      border-radius: 5px;
      min-width: 100px;
      padding: 11px 20px;
      border: 1px solid $dark_neutral;
      background: transparent;
      font-family: 'Hiragino Sans';
      font-style: normal;
      font-weight: 600;
      font-size: 14px;
      line-height: 14px;

      &:last-child{
        background: $dark_neutral;
        color: $white;
      }
    }
  }
}
.video-info-wrapper {
  min-height: 250px;
  p {
    word-break: break-all;
    white-space: normal;
  }
}
.language-switch {
  display: inline;
  margin-left: 10px;
  .active {
    background-color: $c-yellow !important;
  }
  button {
    background-color: #efefef;
  }
}

.nav-pills {
  .nav-link {
    background-color: white;
    color: $c-gray-light;
    border-radius: 0.25rem 0.25rem 0 0;
  }
  .nav-link.active {
    background-color: $c_yellow;
    color: $c_black;
  }
}

.dps-button-primary {
  color: #ffffff;
  background-color: #02c102;
  padding: 5px 20px;
  text-align: center;
  border-radius: 30px;
  border: 1px solid #02c102;
  &[disabled] {
    background-color: $c_gray;
    border: 1px solid $c_gray;
  }
}

.dps-button-secondary {
  color: #ffffff;
  background-color: #000;
  padding: 2px 12px;
  text-align: center;
  border-radius: 5px;
  border: 1px solid #000;
  white-space: nowrap;
  &[disabled] {
    background-color: $c_gray;
    border: 1px solid $c_gray;
  }
}

.dps-input-primary {
  height: 30px;
  border-radius: 5px;
  border: 2px solid #9de79d;
  background-color: #ccf3cc;
  color: #4b4b4b;
  text-align: left;
  padding-left: 12px;
}

.fix-icon-center-ios {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.fix-radio-button-ios {
  position: relative;
  left: 4px;
}

.small-marker {
  color: black;
  font-size: 12px;
  float: right;
  margin-right: 20px;
  position: relative;
  z-index: 10;
}
.small-marker-status {
  color: black;
  font-size: 12px;
  float: right;
  padding-right: 20px;
  position: relative;
}
.alert-wrapper {
  width: 300px !important;
  min-width: 300px;
  margin: 0 !important;
  overflow-x: hidden;
  overflow-y: auto;
}
.alert-title {
  color: red;
}
.alert-list {
  overflow-x: hidden;
  overflow-y: auto;
  max-height: 100px;
  min-height: 100px;
  font-size: 12px;
  margin-left: 32px;
  color: red;
}
//for drag noti
.box-1 {
  max-width: 320px;
  max-height: 320px;
  position: absolute;
  display: flex;
  z-index: 100;
  flex-direction: column;
  background-color: #ccc;
  color: #000;
  border-radius: 10px;
  padding: 30px 30px 70px;
  position: absolute;
  left: 0;
  bottom: 0;
  border: 1px solid #bcbcbc;
  .button-wrapper {
    height: 30px;
    width: 30px;
    min-width: 30px;
    border-radius: 100%;
    background-color: #fc405b;
    color: #ffffff;
    border: 0px;
    position: absolute;
    top: -10px;
    right: -10px;
    .close-btn {
      width: 30px;
      font-size: 20px;
      color: white;
      align-self: flex-end;
      background-color: transparent;
      border: none;
      margin-bottom: 10px;
      opacity: 1;
    }
  }
}

.switch {
  flex-shrink: 0;
  position: relative;
  display: inline-block;
  width: 38px;
  height: 21px;
}

.switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

.switch_language{
  width: 45px;

  .slider{
    display: flex;
    padding: 1px 6px;
    justify-content: space-between;
    align-items: center;
  }
  .lang{
    display: inline-block;
    font-weight: bold;
    color: $white;
  }

  input:checked + .slider:before {
    -webkit-transform: translateX(25px);
    -ms-transform: translateX(25px);
    transform: translateX(25px);
  }
}

.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

.slider:before {
  position: absolute;
  content: "";
  height: 17px;
  width: 17px;
  left: 2px;
  bottom: 2px;
  background-color: white;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

input:checked + .slider {
  background-color: $tertiary; 
}

input:checked + .slider:before {
  -webkit-transform: translateX(17px);
  -ms-transform: translateX(17px);
  transform: translateX(17px);
}

/* Rounded sliders */
.slider.round {
  border-radius: 34px;
}

.slider.round:before {
  border-radius: 50%;
}

.detail_setting_mode {
  p {
    font: 400 12px 'Inter', sans-serif;
  }
}

.notification-url {
  div.notification-url-input {
    width: 80%;
    input {
      flex: 1;
      border: 0;
      padding: 4px 16px;
      border: 1px solid $in_border;
      border-radius: inherit;
      font: 400 16px 'Inter', sans-serif;
    }
  
    button {
      border: 0;
      padding: 8px 16px;
      color: $white;
      background: $dark_neutral;
      font: 400 16px 'Inter', sans-serif;
      height: 40px;
    }
  
    button:hover {
      cursor: pointer;
    }
  }

  div.notification-url-error {
    span {
      color: red;
    }
  } 
}

.preview-group{
  width: 100%;
  display: flex;
  justify-content: space-between;
  margin-bottom: 29px;

  & > div{
    width: calc(50% - 15px);
  }

  .preview-header{
    width: 100%;
    display: flex;
    align-items: center;
    margin-bottom: 8px;
    margin-left: 3px;
    
    .preview-title{
      margin-bottom: 0;
      margin-right: 52px;
      font: 700 16px 'Inter', sans-serif;
      display: inline-flex;
      align-items: center;
      white-space: nowrap;

      svg{
        margin-right: 5px;
        flex-shrink: 0;
      }
    }

    a {
      text-decoration: none;
      background: unset;
      border: 0;
      color: $secondary;
      margin: 0 12px;
      font: 400 14px 'Inter', sans-serif;
      display:-webkit-box;
      -webkit-line-clamp:1;
      -webkit-box-orient: vertical;
      overflow: hidden;
      text-overflow: ellipsis;
      word-break: break-word;
    } 
  }

  .preview-content{
    width: 100%;
    border-radius: 15px;

    .table-preview{
      width: 100%;
      font-size: 14px;
      font-weight: normal;

      tr{
        display: table;
        min-height: 60px;
        
        td:first-child{
          width: auto;
          text-align: left;
          font: 700 14px 'Inter', sans-serif;
        }

        border-bottom: 1px solid $border;
        &:last-child{
          font: 400 14px 'Inter', sans-serif;
          border-bottom: 0;
        }
      }
    }
  }
}

// Custom sensor info balloon
.sensor-info-balloon{
  position: relative;
  display: flex;
  align-items: center;

  svg {
    width: 18px;
    height: 18px;
  }

  .sensor-desc{
    max-width: 180px;
    padding: 10px 12px;
    border-radius: 8px;
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.25);
    position: absolute;
    top: calc(100% + 12.5px);
    left: 50%;
    transform: translateX(-50%);
    background: #3e3a39CC;
    font-size: 12px;
    font-weight: 400;
    color: #fff;
    text-align: left;
    transition: 0.3s linear;
    opacity: 0;
    visibility: hidden;

    h4{
      font-weight: 700;
      margin-bottom: 0;
      color: #fff;
      font-size: 12px;
    }

    table {
      min-width: 136px;
      width: 100%;

      tr{
        line-height: 14px;
        display: flex;
        justify-content: space-between;
  
        td {
          font-size: inherit;
          display:-webkit-box;
          -webkit-line-clamp:1;
          -webkit-box-orient: vertical;
          overflow: hidden;
          text-overflow: ellipsis;
          word-break: break-word;
    
          &:first-child{
            padding-right: 7px;
            font-weight: 700;
            width: 70%;
          }
          &:last-child {
            text-align: right;
            width: 30%;
          }
        }
      }
    }

    &::before{
      content: '';
      position: absolute;
      bottom: 100%;
      left: 50%;
      transform: translateX(-50%);
      width: 0;
      height: 0;
      border-left: 8.5px solid transparent;
      border-right: 8.5px solid transparent;
      border-bottom: 21px solid #3e3a39CC;
    }
  }

  &:hover .sensor-desc {
    opacity: 1;
    visibility: visible;
  }
}

// Playing Tracking (Result)
.charging-station-status{
  display: flex;
  align-items: center;
  flex-direction: column;

  span{
    display: inline-block;
    font: 700 11px 'Inter';
    margin-bottom: 4px;
  }

  p{
    font: 700 16px 'Inter';
    margin: 0;
  }

  img{
    width: 94px;
    height: 94px;
    object-fit: contain;
    margin: 0 10px;
  }
}

.sensor-info{
  width: 100%;

  .ss-row{
    display: flex;
    border-bottom: 1px solid #D9D9D9;
    .ss-col {
      flex: 1;
      font-size: 13px;
      line-height: 21px;

      &:first-child{
        text-align: left;
        padding-left: 10px;
        display:-webkit-box;
        -webkit-line-clamp:2;
        -webkit-box-orient: vertical;
        overflow: hidden;
        text-overflow: ellipsis;
        word-break: break-word;
      }

      &:last-child{
        text-align: right;
        padding-right: 10px;
      }
    }
  }

  .sensor-info-header{
    .ss-col:first-child{
      font-size: 12px;
    }
    .ss-col:last-child span{
      font-size: 10px;
      font-weight: 400;
    }
  }

  .sensor-info-body{
    .ss-col:first-child{
      color: #5F55C4;
      font-weight: 500;
    }
    .ss-col:last-child{
      color: $dark_neutral;
      font-weight: 400;
    }
  }
}

.playing-tracking_wrapper{
  --padding: 6px;
  width: max-content;
  padding: 0;
  position: relative;
  max-width: calc(100% - 250px);

  &.scroll{
    position: relative;

    &.scroll-l::before{
      content: "";
      pointer-events: none;
      position: absolute;
      top: 0;
      left: 0;
      z-index: 1;
      width: 12px;
      height: 100%;
      background: linear-gradient(to right, #fff, #ffffff00);
    }
    
    &.scroll-r::after{
      content: "";
      pointer-events: none;
      position: absolute;
      top: 0;
      right: 0;
      z-index: 1;
      width: 12px;
      height: 100%;
      background: linear-gradient(to left, #fff, #ffffff00);
    }
  }

  .box_info{
    &_item {
      position: absolute;
      z-index: 100;
      bottom: calc(100% - 10px);
      opacity: 0;
      visibility: hidden;
      transition: 0.3s linear;
      transition-property: opacity, visibility;
      transform: translateX(-50%);

      .sensor-desc{
        opacity: 0;
        visibility: hidden;
        top: unset;
        bottom: calc(100% + 16px);

        &::before{
          content: '';
          position: absolute;
          bottom: unset;
          top: 100%;
          left: 50%;
          transform: translateX(-50%);
          width: 0;
          height: 0;
          border-left: 8.5px solid transparent;
          border-right: 8.5px solid transparent;
          border-top: 21px solid #3e3a39CC;
          border-bottom: unset;
          pointer-events: none;
        }
      }
    }
    &_item.active{
      opacity: 1;
      visibility: visible;

      .sensor-desc{
        opacity: 1;
        visibility: visible;
      }
    }
  }

  .first_item{
    width: 70px;
    white-space: nowrap;
    position: absolute;
  }

  .container_scroll{
    margin: 0 auto;
    overflow-x: scroll;
    flex: 1;
    margin-bottom: 6px;
    scroll-behavior: smooth;

    &::-webkit-scrollbar{
      display: none;
    }

    .playing_tracking{
      width: fit-content;
      background: $dark_neutral;
      border-radius: 100rem;
    }

    .box_scroll{
      --circle: 24px;
      width: fit-content;
      display: flex;
      // align-items: flex-start;
      gap: 30px;
      margin: auto;
      padding: var(--padding);
      position: relative;
      transition: transform 0.25s linear;

      .box_item{
        display: flex;
        justify-content: center;
        width: var(--circle);
        border-radius: 100rem;
        position: relative;
        white-space: nowrap;

        & > * {
          pointer-events: none;

        }

        svg{
          margin: 0;
          width: 100%;
        }

        span{
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
          font: 700 12px 'Inter', sans-serif;
          color: $primary ;
        }

        // takeoff
        &.takeoff span{
          top: 45%;
        }

        // Landed
        &.landed span{
          top: 60%;
          transform: translate(-50%, -50%) rotate(180deg);
        }
      }

      .playing{
        position: absolute;
        top: var(--padding);
        bottom: var(--padding);
        left: var(--padding);
        background: $primary;
        border-radius: 100rem;
        display: flex;
        align-items: center;
        justify-content: flex-end;
        transition: width 0.5s linear;

        .drone{
          width: 18px;
          height: 18px;
          background: #524E4D;
          border-radius: 100rem;
          position: relative;
          transition: transform 0.5s linear;
          pointer-events: none;

          &::before{
            content: '';
            position: absolute;
            width: 6px;
            height: 6px;
            background: $danger;
            border-radius: 100rem;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
          }
        }
      }

      .playing_marker{
        height: var(--circle);
      }
    }
  }
  
  .result_info {
    display: flex;
    align-items: center;
    
    .first_title{
      font: 700 12px 'Inter', sans-serif;
    }
    
    .status_info .box_scroll{
      padding: 0 10px !important;
    }

    .current_time{
      display: flex;
      flex-direction: column;
      align-items: center;

      p{
        font: 400 10px 'Inter', sans-serif;
        margin-bottom: 2px;

        &:last-of-type{
          margin-bottom: auto;
        }
      }

      .capture {
        padding: 0;
        background: transparent;
        border: 0;
        pointer-events: all;

        img{
          width: 20px;
        }
      }

      .capture-left {
        position: absolute;
        bottom: 0;
        left: calc(100% + 15px);
        transform: translateX(-50%);
      }
    }
    .current_info{
      font: 400 12px 'Inter', sans-serif;
    }
  }

  // Status Playing Tracking (Flight)
  .drone-play{
    position: absolute;
    top: var(--padding);
    bottom: var(--padding);
    left: var(--padding);
  }
}

.time-up{
  font: 500 12px 'Inter', sans-serif;
}

// Custom Form Select
.mask-select{
  display: flex;
  justify-content: space-between;
  align-items: center;
  border: 1px solid $in_border;
  border-radius: 3px;
  overflow: hidden;
  position: relative;
  height: 48px;

  svg {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    pointer-events: none;

    &:first-of-type{
      left: 15px;
    } 
    &:last-of-type{
      right: 15px;
      color: $secondary;
    } 
  }

  select, input{
    outline: none;
    min-width: 300px;
    margin: 0;
    height: 48px;
    padding: 0px 45px;
    appearance: none;
    -webkit-appearance: none;
    border: 0;
    background: transparent;
  }

  input{
    padding: 3px 8.25px;
    padding-left: 45px;
  }
}

//Flight Height
.flight-height{
  width: 36px;
  padding: 10px 5px 9px;
  background: #0000004D;
  border-radius: 4px;
  display: flex;
  align-items: center;
  pointer-events: none;
  user-select: none;

  .flight-height_content{
    height: calc(100% + 12px);
    display: flex;
    flex-direction: column-reverse;
    justify-content: space-between;
    flex: 1;

    .step_height {
      color: #fff;
      font: 700 10px 'Inter', sans-serif;
      line-height: 12px;
    }
  }

  .flight-height_try{
    width: 5px;
    position: relative;
    display: flex;
    flex-direction: column-reverse;
    align-items: center;

    .try_vertical {
      width: 1px;
      background: $white;
      flex-shrink: 0;
    }
    .try_horizontal {
      width: 5px;
      height: 1px;
      background: $white;
    }
    .drone_position {
      position: absolute;
      left: 50%;
      bottom: -1px;
      transform: translateX(-50%);
      width: 5px;
      height: 2px;
      background: $danger;
      transition: transform 0.5s linear;
    }
  }
}


// Customer swicth input radio
.input-radio-container{
  display: inline-block;
  position: relative;
  width: 18px;
  height: 18px;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  /* Hide the browser's default radio button */
  input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
  }
  /* Create a custom radio button */
  .checkmark {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    border-radius: 50%;
    border: 1px solid $border;
  }
  /* Create the indicator (the dot/circle - hidden when not checked) */
  .checkmark:after {
    content: "";
    position: absolute;
    display: none;
  }
  /* Show the indicator (dot/circle) when checked */
  input:checked ~ .checkmark:after {
    display: block;
  }
  /* Style the indicator (dot/circle) */
  .checkmark:after {
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 10px;
    height: 10px;
    border-radius: 50%;
    background: $secondary;
  }
}

// Customer scroll bar
.ct-scroll {
  /* width */
  &::-webkit-scrollbar {
    width: 7px;
    height: 7px;
  }

  /* Handle */
  &::-webkit-scrollbar-thumb {
    background: #80808080; 
    border-radius: 100rem;
  }
}

// Footer Page
.footer-page{
  width: 100%;
  margin-top: 14px;
  display: flex;
  align-items: center;

  .total-list{
    margin: 0;
    margin-left: 3px;
    font: 400 11px 'Inter', sans-serif;
  }
}
// end customer footer page

// Pagination component
.pagination-component{
  display: flex;
  margin-left: auto;

  & > .pagination {
    margin: 0;

    .page-link{
      font-size: 12px;
      font-weight: bold;
      color: #212529;

      & > span {
        font-size: inherit;
      }
    }

    .page-item.active .page-link{
      background: $dark_neutral;
      border-color: $dark_neutral;
      color: $white;
    }
    .page-item.disabled{
      cursor: no-drop;
      user-select: none;
    }
  }

  & > label{
    align-self: center;
  }

  & > .mask-select{
    margin: 0 12px;
    height: unset !important;
    background: $white;
    border: $card-border;
    overflow: unset;

    select{
      min-width: 100px;
      height: unset;
      padding: 0 45px 0 10px;
      color: inherit;
    }

    svg {
      left: unset;
      margin: 0;
    }
  }
}

// end customer pagination

.modal-dialog.modal-fullscreen {
  .device_tracking-marker{
    svg{
      width: 18.22px;
      height: 18.22px;
    }

    .capture svg{
      width: 10px;
      height: 10px;
    }

    .flight-next{
      left: -14px;
      svg {
        width: 45.56px;
        height: 45.56px;
      }

      span {
        font-size: 23px;
      }
    }
  }

  .status-map-view, .map-image-preview {
    .marker-item {
     .takeoff, .landing {
       span svg {
         width: 9.11px;
         height: 7.89px;
       }
     }
   }
  }

  .intro-banner-vdo-play-btn{
    width: 24px;
    height: 24px;
    svg{
      width: 40px;
      height: 24px;
    }
    .ripple{
      width: 56px;
      height: 56px;
    }
  }
}

// Custom check-box
.form-checkbox {
  width: 18px;
  height: 18px;
  margin-left: 8px;
  margin-right: 5px;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px solid $in_border;
  border-radius: 3px;
  cursor: pointer;

  input{
    display: none;

    &:checked + svg {
      display: block;
    }
  }

  svg{
    display: none;
  }
}
// Custom radio-box
.form-radio{
  display: inline-block;
  position:relative;
  cursor:pointer;
  font-size: 12px;
  font-weight: 400;
  user-select: none;
  padding-left: 26px;

  input {
    position: absolute;
    opacity: 0;
    cursor: pointer;

    &:checked ~ .checkmark::after {
      display: block;
    }
  }

  .checkmark{
    position:absolute;
    left: 0;
    top: 50%;
    transform: translateY(-50%);
    width: 19px;
    aspect-ratio: 1;
    background: transparent;
    border-radius:50%;
    border: 1px solid $in_border;

    &::after{
      content: '';
      width: 10px;
      height: 10px;
      background: #5F55C4;
      border-radius: 100rem;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      flex-shrink: 0;
      display: none;
    }
  }
}

// Custom Chart Sensor
.chart {
  width: 100%;
  overflow-x: auto;
  display: flex;
  justify-content: center;

  & > .canvas {
    max-height: 100%;
    height: fit-content;
    flex: 1;
    position: relative;

    canvas {
      width: 100% !important;
      height: unset !important;
      aspect-ratio: 2;
    }

    button {
      position: absolute;
      left: 0;
      opacity: 0;
    }
  }
  
  & > div.legend {
    padding-left: 10px;
    min-width: 190px;
    overflow: hidden;
    display: flex;
    flex-direction: column;
    align-items: flex-start;

    .legend-list{
      display: flex;
      flex-direction: column;
      gap: 5px;
      width: 100%;
      margin: 0;
      padding: 0;

      .legend-item{
        display: flex;
        align-items: center;
        height: 21px;
        cursor: pointer;
        border-radius: 3px;
        
        span {
          display: inline-block;
          width: 30px;
          height: 3px;
          margin: 0 8px;
        }
        
        p {
          font-size: 12px;
          font-weight: 500;
          line-height: 16px;
          margin: 0;
          padding: 0;
        }
        
        &:hover {
          background: #F2F2F2;
        }

        &.disable-line {
          span {
            opacity: 0;
            visibility: hidden;
          }

          p{
            color: #9F9F9F;
          }

          &:hover {
            span {
              opacity: 1;
              visibility: visible;
            }
            p{
              color: #979797;
            }
          }
        }
      }
    }

    .normalize{
      display: flex;
      flex-direction: column;
      gap: 4px;
      margin-top: 12px;
    }
  }
}

// Custom Table Sensor
.table-sensor-custom{
  width: 100%;
  flex-grow: 1;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  position: relative;

  &::before, &::after{
    content: '';
    position: absolute;
    left: 0;
    width: 100%;
    height: 1px;
    background: $dark_neutral;
  }
  &::before{
    top: 0;
  }
  &::after{
    top: 45px;
  }

  table {
    table-layout: fixed;
    width: 100%;

    td{
      text-align: center;
      font-size: 13px;
      text-overflow: ellipsis;
      overflow: hidden;
      color: $dark_neutral;

      span {
        color: inherit;
        font-weight: inherit;
        display:-webkit-box;
        -webkit-line-clamp:1;
        -webkit-box-orient: vertical;
        overflow: hidden;
        text-overflow: ellipsis;
        word-break: break-word;
      }

      &:first-child{
        font-weight: 700;
        color: inherit;
        text-transform: capitalize;
        width: 60px;
      }
      &:nth-child(2){
        width: 140px;
        color: inherit;
        text-transform: capitalize;
      }
    }
  }

  .header{
    height: 46px;

    td{
      padding: 5px 0;
      font-weight: 500;
      color: #5F55C4;
      line-height: normal;
    }

    &.scroll-body{
      width: calc(100% - 16px);
    }
  }
  

  .body{
    flex: 1;
    overflow: auto;

    tr{
      border-bottom: 1px solid #DBDBDB;

      td {
        padding: 5px 0;

        &:nth-child(2) {
          font-size: 12px;
        }
      }
    }
  }
}

.switch-sensor-view{
  display: flex;
  border-radius: 4px;
  overflow: hidden;

  button {
    display: flex;
    justify-content: center;
    align-items: center;
    border: 0;
    width: 76px;
    height: 26px;
    background: #E9E8F7;
    color: #5F55C4;
    font-size: 11px;
    gap: 3px;

    svg path {
      stroke: #5F55C4;
    }

    &.active{
      background: #5F55C4;
      color: #fff;
      svg path {
        stroke: #fff;
      }
    }
  }
}

.sensors-result{
  background: #fff;
  width: 100%;
  height: 100%;
  border-radius: inherit;
  display: flex;
  flex-direction: column;
  overflow: hidden;

  .btn_csv{
    color: #5F55C4;
    border: 1px solid #5F55C4;
    border-radius: 4px;
    background: transparent;
    width: max-content;
    height: 26px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-right: 30px;
    text-decoration: none;
    padding: 1px 6px;
    font-size: 12px;
    font-weight: 400;

    svg{
      width: 16px;
      fill: #5F55C4;
      margin-right: 10px;
    }
  }

  .btn_hidden_sensor-result{
    display: flex;
    justify-content: center;
    align-items: center;
    height: 22px;
    border: 0;
    background: transparent;
    padding: 0;
  }

  .sensors-result-header {
    display: flex;
    align-items: center;
    margin-bottom: 16px;
    
    .flight-chart-info{
      color: $dark_neutral;
      font-size: 16px;
      text-align: left;

      p{
        font-size: inherit;
        font-weight: 500;
        margin-bottom: 0;
        display: flex;
        flex-wrap: wrap;

        span{
          font-size: inherit;
          font-weight: inherit;
          margin-right: 10px;
        }
      }
    }

    .actions {
      align-self: flex-start;
      display: flex;
      align-items: center;
      margin-left: auto;

      .btn-full {
        padding: 0;
        border: 0;
        aspect-ratio: 1;
        background: transparent;
        margin-left: 20px;
      }

      .btn_hidden_sensor-result{
        height: 18px;
        aspect-ratio: 1;
        flex-shrink: 0;
        margin-left: 16px;

        svg {
          height: 100%;
        }
      }
    }
  }

  & > .sensor-chart {
    flex: 1;
    overflow-y: auto;
  }
}

// Modal custom
.modal-custom-wrapper{
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 99;
  display: flex;
  justify-content: center;
  align-items: center;
  box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15);
  opacity: 0;
  visibility: hidden;
  transition: all 0.4s ease;

  &.modal-overlay {
    background: rgba(0, 0, 0, 0.5);
  }

  &.active {
    opacity: 1;
    visibility: visible;

    .modal-content{
      transform: translateY(0);
    }
  }

  .modal-content{
    background: #fff;
    transform: translateY(-10%);
    transition: 0.4s ease;
  }
}

// Img preview result
.card-img{
  width: 210px;
  padding: 10px;
  background: #F5F5F4;
  border: 1px solid #DAD8D7;
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  
  img{
    cursor: pointer;
    width: 100%;
    aspect-ratio: 16 / 9;
    margin-bottom: auto;
    object-fit: contain;
    border-radius: 5px;
  }

  .card-info{
    text-align: center;
    margin-top: 8px;

    p{
      color: $dark_neutral;
      margin-bottom: 0;
      font-size: 11px;
      font-weight: 400;
      line-height: 20px;
      display:-webkit-box;
      -webkit-line-clamp:1;
      -webkit-box-orient: vertical;
      overflow: hidden;
      text-overflow: ellipsis;
      word-break: break-word;

      &:last-child{
        line-height: 15px;
      }
    }
  }

  &.selected{
    box-shadow: 0 0 0 3px #5F55C4B2;
  }
}

// Images custom image list view
.images-wrapper{
  width: 100%;
  display: flex;
  flex-direction: column;

  .image-header{
    display: flex;
    align-items: center;
    justify-content: space-between;

    .image-preview-info{
      margin-right: auto;
      margin-left: 10px;
      color: #000;
      font: 400 12px Inter;
      line-height: 14px; /* 116.667% */
      display:-webkit-box;
      -webkit-line-clamp:1;
      -webkit-box-orient: vertical;
      overflow: hidden;
      text-overflow: ellipsis;
      word-break: break-word;
    }

    .actions{
      display: flex;
      align-items: center;
      gap: 4px;

      .img-selected {
        display: flex;
        height: 24px;
        min-width: 36px;
        padding: 0px 9px;
        justify-content: flex-end;
        align-items: center;
        border-radius: 12px;
        background: $dark_neutral;
        color: #FFF;
        text-align: center;
        font: 400 12px Inter;
        line-height: 14px; /* 116.667% */
      }

      button {
        padding: 0;
        margin: 0;
        background: transparent;
        border: 0;
        width: 32px;
        height: 32px;
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: 5px;
        position: relative;

        & > svg, & > img {
          pointer-events: none;
        }

        &.btn-select-all{

          img {
            width: 24px;
          }

          &.active{
            box-shadow: 0 0 0 1px #5F55C4;
          }
        }

        &.btn-sort-image {
          .form-sort {
            position: absolute;
            top: calc(100% + 10px);
            right: 0;
            padding: 5px 12px;
            background: #34495e;
            list-style-type: none;
            border: 1px solid #636e72;
            border-radius: 5px;
  
            
            label {
              display: flex;
              align-items: center;
              margin-bottom: 6px;
              cursor: pointer;
              
              input{
                display: none;
              }
  
              p{
                margin: 0;
                font-size: 13px;
                font-weight: 500;
                color: #fff;
                margin-left: 10px;
              }
  
              span {
                opacity: 0;
                visibility: hidden;
              }
              
              & > input:checked + span {
                opacity: 1;
                visibility: visible;
              }
            }
          }
        }

        &.btn-filter-image {
          .form-filter {
            width: 150px;
            position: absolute;
            top: calc(100% + 10px);
            right: 0;
            padding: 5px 12px;
            padding-bottom: 16px;
            background: #34495e;
            list-style-type: none;
            border: 1px solid #636e72;
            border-radius: 5px;
            cursor: default;
            display: flex;
            gap: 12px;
            
            .mask {
              position: relative;
  
              &::before{
                content: "_";
                font-size: 13px;
                position: absolute;
                left: 12px;
                top: 5px;
              }
  
              input{
                width: 100%;
                height: 100%;
                padding-left: 18px;
                font-size: 13px;
                color: #000;
              }
  
              span {
                color: #fff;
                position: absolute;
                top: 100%;
                left: 0;
                margin-top: 2px;
                font-size: 10px;
                color: $danger-250;
                font-weight: 500;
              }
            }
  
            button{
              font-weight: 500;
              color: #fff;
              background: #0abde3;
              padding: 0 8px;
              border-radius: 3px;
              margin-left: auto;
              display: flex;
              justify-content: center;
              align-items: center;
            }
          }
        }

        &.btn-show-list{}
      }
    }
  }

  .image-body{
    width: calc(100% + 6px);
    margin-left: -3px;
    display: flex;
    flex-wrap: wrap;
    gap: 16px;
    overflow: auto;
    padding: 3px;

    .image-item {
      width: 200px;
      display: flex;
      padding: 10px;
      flex-direction: column;
      align-items: center;
      gap: 8px;
      border-radius: 8px;
      text-align: center;
      border: 1px solid #CAC5C4;
      background: #F1EFEE;

      img{
        width: 100%;
        height: 100px;
        object-fit: contain;
        cursor: pointer;
      }

      .image-item-info {
        width: 100%;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        gap: 2px;

        .image-name{
          overflow: hidden;
          color: $dark_neutral;
          text-overflow: ellipsis;
          white-space: nowrap;
          font: 400 11px Inter;
          line-height: 20px; /* 181.818% */
          margin: 0;
        }

        .image-time{
          color: $dark_neutral;
          font: 400 10px Inter;
          line-height: 15px; /* 150% */
        }
      }

      &.selected{
        box-shadow: 0 0 0 3px #5F55C4B2;
      }
    }

    // image by marker
    .img-carousel{
      width: 100%;
      display: flex;
      flex-direction: column;

      .img-selected{
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: space-between;

        button {
          padding: 0;
          margin: 0;
          background: transparent;
          border: 0;
          cursor: pointer;

          &.btn-pre {}

          &.btn-next {}
        }

        .img-wrap {
          flex: 1;
          margin: 0 10px;
          position: relative;

          img {
            display: block;
            max-width: 100%;
            object-fit: contain;
            margin: auto;
            max-height: 350px;
          }
        }
      }

      .img-dots{
        width: 100%;
        padding: 12px;
        display: flex;
        align-items: center;
        gap: 10px;
        align-self: stretch;
        overflow-x: auto;

        .dot-item {
          display: flex;
          padding: 5px 6px;
          flex-direction: column;
          align-items: center;
          gap: 2px;
          cursor: pointer;
          margin: auto;

          img {
            width: 120px;
            height: 67.5px;
            object-fit: contain;
            border-radius: 3px;
          }

          span {
            color: #000;
            font: 400 9px Inter;
            text-align: center;
            line-height: 14px; /* 155.556% */
          } 

          &.selected {
            border: 1.5px solid #5F55C4B2;
            background: #E9E8F7;
            border-radius: 6px;
          }
        }
      }
    }

    .img-detail-wrap{
      width: 100%;
      height: 100%;
      position: relative;
      display: flex;

      button {
        padding: 0;
        margin: 0;
        background: transparent;
        border: 0;
      }

      .btn-close-detail-img{
        position: absolute;
        top: 0;
        left: 0;
      }

      .img-view{
        max-height: 100%;
        flex: 1;
        display: flex;
        align-items: center;
        justify-content: space-between;
        gap: 10px;
        border-right: 1px solid #D4D4D4;
        padding: 0 10px; 

        .img-wrap {
          flex: 1;
          height: 100%;
          display: flex;
          justify-content: center;
          align-items: center;
          position: relative;

          img {
            display: block;
            min-width: 180px;
            max-width: 100%;
            max-height: 100%;
            object-fit: contain;
            margin: auto;
          }
        }
      }
      
      .img-metadata {
        display: flex;
        flex-shrink: 0;
        width: 185px;
        height: 100%;
        padding: 12px 10px;
        flex-direction: column;
        align-items: flex-start;
        overflow: auto;
        position: relative;

        .col-cs span{
          color: $dark_neutral;
          font: 500 11px Inter;
          line-height: 15px; /* 136.364% */
        }
      }
    }

    .loading{
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
    }
  }

  .no-img {
    color: var(--gray-06-disabled, #AAA3A2);
    font: 700 14px Inter;
    line-height: 21px; /* 150% */
    flex: 1;
    display: flex;
    justify-content: center;
    align-items: center;
  }
}

.modal-img-preview{
  width: calc(100vw - 240px) !important;
  height: calc(100vh - 20px) !important;
  margin: 10px !important;
  margin-left: auto !important;
  
  &.full-screen-mode{
    width: unset !important;
    height: 100vh !important;
    margin: unset !important;
  }

  .box-img-preview{
    width: 100%;
    height: 100%;
    background: $black;
    overflow: hidden;
    display: flex;
  
    .img-preview{
      flex: 1;
      display: flex;
      flex-direction: column;
      align-items: center;
      padding: 10px;
      position: relative;

      button{
        border: 0;
        padding: 0;
        background: transparent;
        display:flex;
        align-items:center;
        justify-content:center;

        &:disabled{
          opacity: 0.4;
          cursor: unset;
        }
      }

      .img-preview-header{
        width: 100%;
        display: flex;
        text-align: center;
        align-items: center;

        button {
          font-size: 32px;
        }

        & > div {
          flex: 1;

          p{
            margin-bottom: 5px;
            color: #fff;
            
            &:first-child{
              font-weight: 500;
              font-size: 16px;
            }
          }
        }

      }
  
  
      .img{
        width: 100%;
        flex: 1;
        display: flex;
        align-items: center;
        justify-content: space-between;
        overflow: hidden;

        img{
          max-height: 100%;
          max-width: calc(100% - 50px);
          object-fit: contain;
        }

        button {
          width: 20px;
          height: 50px;

          &:first-of-type {
            &::before{
              content: "";
              position: absolute;
              width: 10px;
              height: 30px;
              background: #fff;
              clip-path: polygon(70% 0, 100% 0, 30% 50%, 100% 100%, 70% 100%, 0 50%);
            }
          }

          &:last-of-type{
            &::before{
              content: "";
              position: absolute;
              width: 10px;
              height: 30px;
              background: #fff;
              clip-path: polygon(0 0, 30% 0, 100% 50%, 30% 100%, 0 100%, 70% 50%);
            }
          }
        }
      }
    }
  
    .img-meta{
      width: 250px;
      padding: 20px 10px;
      font-size: 12px;
      color: #fff;
      background: $dark_neutral;
      overflow-y: auto;
      overflow-x: hidden;

      &::-webkit-scrollbar {
        width: 6px;
      };
      &::-webkit-scrollbar-thumb {
        background-color: #777777;
        border-radius: 3px;
      };
    }
  }
}

.metadata-table {
  width: 100%;

  .row-cs{
    display: flex;
    flex-wrap: nowrap;
    gap: 3px;

    .col-cs {
      &:first-child {
        width: 60%;
      }
      &:last-child {
        width: 40%;
      }
      span {
        color: #fff;
        word-wrap: break-word;
      }
    }
  }
}

.modal-img-preview-mb{
  width: 100vw !important;
  height: 100% !important;
  margin: unset !important;
  margin-left: unset !important;

  .img > img {
    max-height: 100%;
  }
}


// End Img preview result

.setting-axis {
  z-index: 2500;

  & * {
    color: #5F55C4 !important;
  }
}

// Kiriha label
.kiriha-label {
  max-width: 100%;
  max-height: 100%;
  background: #0D7EF0;
  opacity: 0.8;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 8px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 1;

  border-radius: 10px;
  color: #fff;
  padding: 8px 35px;
  font-weight: 500;
  font-size: 14px;
  text-align: center;
}

.kiriha-blink {
  animation: kiriha-blinker 1s ease infinite;
}

@keyframes kiriha-blinker {
  50% {
    opacity: 0.5;
  }
}

.check_is_tablet {
  // check tablet
  @media screen and (max-width: 1181px) and (min-width: 720px) {
    .preview-group .preview-header .preview-title {
      margin-right: 0px;
    }
  }

}

// check mobile
@media (max-width: 845px) {}


.react-datepicker__day--keyboard-selected,
.react-datepicker__month-text--keyboard-selected,
.react-datepicker__quarter-text--keyboard-selected,
.react-datepicker__year-text--keyboard-selected {
  background-color: #{$secondary}4d;
  &:hover {
    background-color: $secondary;
    color: #fff;
  }
}

.react-datepicker__day--selected, .react-datepicker__day--in-selecting-range, .react-datepicker__day--in-range,
.react-datepicker__month-text--selected,
.react-datepicker__month-text--in-selecting-range,
.react-datepicker__month-text--in-range,
.react-datepicker__quarter-text--selected,
.react-datepicker__quarter-text--in-selecting-range,
.react-datepicker__quarter-text--in-range,
.react-datepicker__year-text--selected,
.react-datepicker__year-text--in-selecting-range,
.react-datepicker__year-text--in-range {
  background-color: $secondary;
}
