// disable pull to refresh
html {
  overflow: hidden;
  overscroll-behavior: none;
}

.quill {
  display: flex;
  flex-direction: column;
  
  .ql-editor{
    overflow-y: unset;
    img {
      height: auto !important;
    }
    strong{
      font-weight: bold !important;
    }
  }
}

.public_route{
  touch-action: pan-y;
  min-width: 650px;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  overflow-y: auto;
  min-height: 100%;
  padding: 30px;
  background: $white;

  .nav_public_route{
    display: flex;
    justify-content: space-between;
    align-items: center;
    .logo{
      margin-bottom: 0;
      height: 50px;

      img{
        height: 100%;
      }
    }

    .btn-login{
      color: $label;
      text-decoration: none;
      font-size: 14px;
      font-weight: bold;
      padding: 6px 18px;
      transition: 0.3s linear;
      border: $card-border;
      border-radius: 3px;
      box-shadow: $card-shadow;

      &:hover{
        background: $dark_neutral;
        color: $white;
        box-shadow: $card-shadow-hover;
      }
    }
  }

  & > h2{
    font-size: 30px;
    text-align: center;
    font-weight: bold;
  }

  & > p{
    margin-bottom: 10px;
    text-align: right;
  }
}

.public_route_mobile{
  min-width: unset;

  & > h2 {
    margin: 20px 0;
  }

  & > .quill {
    min-width: unset !important;
  }
}