.m-content{
  position: fixed;
  top: 86px;
  left: 0;
  right: 0;
  bottom: 74px;
  padding: 0 20px;
  overflow: auto;

  //view
  .scan-title{
    font: 700 20px 'Inter', sans-serif;
    margin-bottom: 116px;
    color: $dark_neutral;
  }

  .scan-form-content{
    width: 300px;
    margin: 0 auto;
    .inputPlanNo > form{
      margin-bottom: 18px;
      position: relative;

      label {
        font: 700 16px "Inter", sans-serif;
        color: $dark_neutral;
        margin-bottom: 14px;
      }

      .scan-dps-input{
        width: 100%;
        border: 1px solid #ccc;
        border-radius: 6px;
        padding: 14px 12px;
        font: 400 16px 'Inter', sans-serif;
      }
    }

    .capture-aruco-code{
      width: 100%;

      input {
        display: none;
      }

      .capture{
        width: 100%;
        padding: 14px 0;
        background: $dark_neutral;
        color: #fff;
        font: 700 16px 'Inter', sans-serif;
        border: 0;
        border-radius: 6px;
      }
    }

    .loading{
      margin-top: 16px;
      img{
        height: 60px;
      }
    }

    .error-message {
      margin-top: 16px;
      height: 60px;
      color: $c-red;
      text-align: center;
      background-color: white;
      border-radius: 10px;
      padding: 0 10px;
      display: flex;
      align-items: center;
    }
  } 

  //plan | marker detail
  .scan-detail-header{
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;

    .plan-no{
      flex: 1;

      label{
        display: block;
        font: 700 16px 'Inter', sans-serif;
        color: $dark_neutral;
        margin-bottom: 13px;
      }

      input {
        width: 127px;
        border: 1px solid #ccc;
        border-radius: 6px;
        padding: 14px 12px;
        font: 400 16px 'Inter', sans-serif;
      }
    }

    img{
      width: 90px;
      height: 90px;
      object-fit: contain;
    }
  }

  .scan-flight-plan {
    background-color: white;
    border-radius: 10px;
    margin: 20px 0;
    padding: 10px;

    table{
      width: 100%;
      
      td {
        padding: 10px;
        font-size: 12px;
        font-weight: 400;

        &:first-child{
          width: 70%;
        }
      }
    } 
  }
}