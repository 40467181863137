.dps-title-schedule{
  h1{
    flex: 0 1 max-content !important;
    margin-right: 63px;
  }

  .btn-add-schedule {
    margin-right: auto;
    width: max-content;
    padding: 0;
    background: unset;
    display: flex;
    align-items: center;
    font-size: 14px;
    svg{
      margin-right: 10px;
      margin-top: -1px;
    }
  }

  .delete{
    height: 30px;
    padding: 0;
    background: transparent;
    border: 0;
    margin-right: 30px;
    padding-right: 30px;
    border-right: 2px solid $in_border;
    margin-left: auto;

    img{
      height: 100%;
      object-fit: contain;
    }
  }
}

.schedule-header{
  display: flex;
  justify-content: space-between;
  align-items: center;

  .schedule-actions{
    padding-bottom: 10px;

    button {
      margin-right: auto;
      width: max-content;
      padding: 0;
      background: unset;
      display: flex;
      align-items: center;
      font-size: 14px;
      gap: 10px;
    }
  }
}

.schedule {
  padding: 0;

  .schedule-add-item {
    height: 70px;
    width: 100%;
    min-height: 70px;
    position: absolute;
    bottom: 0;
    background-color: $c-green-light;
    border-radius: 10px;
    padding: 20px;
    justify-content: space-between;

    input[type="checkbox"] {
      width: auto;
      margin-right: 8px;
    }

    .btn-save, .btn-upload, .btn-add {
      width: 30px;
      height: 30px;
      border-radius: 20px;
      border: none;
      display: inline-flex;
      flex-direction: row;
      align-items: center;
      background-color: $c-green;

      svg {
        margin: 0 auto;
      }
    }

    .btn-save {
      align-self: flex-end;
    }

    .btn-upload {
      background-color: $c-black;
    }

    div {
      align-self: stretch;
      position: relative;
      margin-right: 10px;
    }

    Input {
      height: 30px;
      border-radius: 5px;
      border: 2px solid #9de79d;
      background-color: #ccf3cc;
      color: $black;
      text-align: left;
      padding-left: 12px;
      margin-right: 8px;
      width: 100%;

    }

    .small {
      width: 50px;
    }

    .large {
      width: 200px;
    }
  }

  .padding-top-label {
    padding-top: 20px;
  }

  .dps-button {
    color: $c_black;
    background-color: $c_yellow;
    font-size: 1rem;
    border-radius: 0.25rem;
    text-align: center;
    padding: 0 10px;;
  }

  .btn-padding-bottom {
    margin-bottom: 10px;
  }

  .schedule-content {
    display: flex;
    flex: 1 0;
    flex-direction: column;
    align-items: start;
    background-color: transparent;
    overflow: auto;
  }

  .dps-list-f {
    .clmn {
      &:nth-child(1) {
        flex: 1 1 100px;
      }
      &:nth-child(2) {
        flex: 1 1 150px;
      }
      &:nth-child(3) {
        flex: 0 1 70px;
        span{
          display: inline-block;
          padding: 1px 10px;
          background: #00000025;
          border-radius: 100rem;
          font-weight: 500;
        }
        text-align: center;
      }
      &:nth-child(4) {
        flex: 1 1 100px;
      }
      &:nth-child(5) {
        flex: 1 1 150px;
      }
      &:nth-child(6) {
        flex: 0 1 100px;
        text-align: center;
      }
      &:nth-child(7) {
        flex: 0 1 150px;
      }
    }
    .body {
      .clmn {
        &:nth-child(1), &:nth-child(2), &:nth-child(4), &:nth-child(5) {
          font-weight: 700;
        }
        svg {
          margin-right: 12px;
        }
      }
    }
  }
}

.schedule_add, .schedule {
  overflow-y: unset;
  overflow-x: unset;
  flex: 1;

  option {
    font: 400 16px 'Inter', sans-serif;
  }
  .form_add{
    width: 100%;

    .form_add-head{
      width: 100%;
      margin-bottom: 20px;
      display: flex;
      justify-content: space-between;

      input{
        flex: 1 0;
        border: 1px solid $in_border;
        border-radius: 3px;
        padding: 12px;
        font: 400 16px 'Inter', sans-serif;
      }

      .btn-save{
        display: block;
        width: max-content;
        height: auto;
        padding: 14px 46px;
        color: $white;
        background: $dark_neutral;
        border-radius: 3px;
        margin-left: 20px;
        font: 700 14px 'Inter', sans-serif;

        &:disabled{
          opacity: 0.5;
          cursor: default;
        }
      }
    }

    .form_add-main{
      width: 100%;
      padding: 30px;
      position: relative;
      background: $white;
      border-radius: 10px;
      border: $card-border;
      box-shadow: $card-shadow;

      input{
        height: auto;
      }

      .form_checkbox{
        min-width: max-content;
        position: absolute;
        top: 30px;
        right: 30px;
        display: flex;
        align-items: center;

        input:checked + svg {
          display: block;
        }

        label{
          display: block;
          width: 18px;
          height: 18px;
          margin-right: 8px;
          border: 1px solid $in_border;
          border-radius: 3px;
          display: flex;
          justify-content: center;
          align-items: center;
          cursor: pointer;

          input, svg{
            display: none;
          }
        }

        span{
          font: 700 12px 'Inter', sans-serif;
        }
      }

      .form_select{
        width: max-content;
        margin-bottom: 20px;
       
     
        label{
          font: 400 12px 'Inter', sans-serif;
        }

        &_plan{
          display: flex;
          align-items: center;

          & > span {
            min-width: max-content;
            display: inline-block;
            margin: 0 30px;
            font: 700 12px 'Inter', sans-serif;
          }

          // customer select plan
          .custom-select{
            height: 48px;
            width: 300px;
            position: relative;
            border: 1px solid $in_border;
            border-radius: 3px;
            display: flex;
            justify-content: space-between;
            align-items: center;
            position: relative;

            & > svg {
              position: absolute;
              pointer-events: none;
              user-select: none;

              &:first-of-type{
                left: 15px;
              }
              &:last-of-type{
                right: 15px;
                color: #473daf;
              }
            }

            .select-content{
              width: 100%;
              height: inherit;

              .selected{
                width: inherit;
                padding: 0 45px;
                cursor: pointer;
                height: inherit;  
                display: flex;
                align-items: center;

                .text{
                  display: block;
                  overflow: hidden;
                  white-space: nowrap;
                  text-overflow: ellipsis;
                  user-select: none;
                  color: #808080;
                  font: 400 16px 'Inter', sans-serif;
                }
              }
              .select-box{
                opacity: 0;
                visibility: hidden;
                width: 100%;
                max-height: 400px;
                overflow-y: auto;
                position: absolute;
                top: 100%;
                left: 0;
                z-index: 10;
                background: #fff;
                border-radius: 3px;
                border: 1px solid gray;
                box-shadow: 0 0 8px gray;

                .select-list{
                  margin: 0;
                  padding: 0;
                  list-style-type: none;

                  li{
                    cursor: pointer;
                    overflow: hidden;
                    white-space: nowrap;
                    text-overflow: ellipsis;
                    padding-left: 45px;
                    font: 400 16px 'Inter', sans-serif;

                    &:hover, &.active {
                      background-color: #1e90ff;
                      color: #fff;
                    }
                  }
                  
                }
              }

              .select-box.active {
                opacity: 1;
                visibility: visible;
              }
              .select-box.drop-up {
                top: auto;
                bottom: 100%;
                max-height: 280px;
              }
            }
          }
        }

        option, input{
          font: 400 16px 'Inter', sans-serif;
        }
      }

      .form_repeat{
        width: max-content;

        & > .form_select{
          display: flex;
          align-items: center;
          margin-bottom: 16px;

          label{
            min-width: max-content;
            margin-right: 30px;
            font-weight: 600;
          }
        }

        .form_repeat-content {
          width: 100%;

          .every_week{
            display: flex;
            align-items: center;

            .every_week-item {
              margin-right: 18px;
              display: flex;
              flex-direction: column;
              justify-content: center;

              input:checked + svg {
                display: block;
              }
      
              label{
                display: block;
                width: 18px;
                height: 18px;
                margin-bottom: 5px;
                border: 1px solid $in_border;
                border-radius: 3px;
                display: flex;
                justify-content: center;
                align-items: center;
                cursor: pointer;
      
                input, svg{
                  display: none;
                }
              }

              span{
                text-align: center;
                font: 400 12px 'Inter', sans-serif;
              }
            }
          }

          .monthly{
            width: 100%;
            display: flex;
            align-items: center;

            label{
              margin-right: auto;
            }
          }

          .hour-minute{
            width: 100%;
            display: flex;
            align-items: center;
            justify-content: space-between;
            margin-top: 10px;
            padding-bottom: 2rem;
            position: relative;

            input {
              margin-left: 10px;
            }

            & > span {
              position: absolute;
              bottom: 0;
              left: 0;
              white-space: nowrap;
            }
          }

          label{
            font: 400 12px 'Inter', sans-serif;
          }
        }

        .mask-select {
          border: unset;

          select{
            min-width: min-content;
            padding: 0 10px;
            padding-right: 25px;
           
          }

          svg{
            left: unset !important;
            right: 10px;
            color: #808080 !important;
          }
        }
      }

      button.delete {
        background: #0000;
        border: 0;
        padding: 0;
        height: 30px;
        position: absolute;
        bottom: 10px;
        right: 10px;

        img {    
          height: 100%;
          object-fit: contain;
        }
      }
    }
  }
}

.form_repeat .form_select {
  svg {
    width: 10px;
    height: 12px;
  }

  .mask-select {
    .dps-select {
    font: 400 12px 'Inter', sans-serif;
  }
} 

}