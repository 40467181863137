$red: #fc405b !default;
$blue: #0089ff;
$green: #CCF3CC;
$orange: #fbbe66;
$black: #4b4b4b;
$textTable: #7b8c92;
$background: #f6f5f4;
$white: #fff;
$bg-red: #fc405b;
$bg-green: #02c102;
$bg-yellow: #fcd33c;
$font-txt: 13px;
$gray: #707070;
$yellow: #fcd33c;
$bg-gray: #9C9C9C;

 
$c-yellow-dark: #BF8F00;
$c-yellow: #fcd33c;
$c-black: #4b4b4b;
$c-green: #02c102;
$c-green-light: #e5f9e5;
$c-red: #fc405b; 
$c-red-light: #ffede5;
$c-white: #ffffff;
$c-gray: #707070;
$c-gray-light: #D6D6D6;

$primary-100: #FEE58C;
$primary-300: #FEDC62;
$primary: #FCD13E;
$primary-700: #EEBB0D;
$primary-900: #B48D05;
$secondary: #473DAF;
$tertiary: #6E34AA;
$success: #198754;
$success-100: #d4f7e7;
$success-250: #93ebc4;
$danger: #DC3545;
$danger-100: #f7d4d7;
$danger-250: #eb939b;
$warning: #FFC107;
$warning-100: #fff2cc;
$warning-250: #ffdf80;
$info: #0D7EF0;
$info-100: #cfe6fc;
$info-250: #87c1f7;
$light: #f8f8f8;
$dark_neutral: #3e3a39;
$in_border: #DDDDDD;
$border: #EBEBEB;
$label: #606060;
$sensor-color: #948ED7;

$card-border: 1px solid #DBD7D6;
$card-shadow: 0px 0px 3px 0px rgba(0, 0, 0, 0.10);
$card-shadow-hover: 0 1px 9px #e2e2e2;
$background-hover: #f7f0ff; //hsl(268, 100%, 97%)
$overlay-hover: #5f55C42b;
