// common UI Grid

.f-col {
  display: flex;
  flex-direction: column;
}

.f-column {
  display: flex;
  flex-direction: column;
}

.f-center {
  align-items: center;
}

//common paragraph
body {
  margin: 0px;
}

p,
h1,
h2,
h3,
h4,
h5,
label,
span,
small,
input {
  font-family: 'Inter', sans-serif;
  font-weight: 300;
  color: $c-black;
  line-height: 1.5em;
}

p,
label,
span {
  font-size: 13px;
}

small {
  font-size: 10px;
}

h1 {
  font-size: 15px;
  font-weight: bold;
}

.w-100 {
  width: 100%;
}

.dps-h-100 {
  height: 100vh;
}

.dps-input {
  color: #808080;
  padding: 10px 20px;
  height: 40px;
  width: 100%;
  border-radius: 3px;
  border: 0px;
  background: $light !important;
  border: 1px solid $border;
}

.login-font-size-revert {
  font-size: revert;
}

.dps-input ~ div {
  position: relative;
}

.input-error {
  // display: none;
  white-space: nowrap;
  position: absolute;
  bottom: -18px;
  left: 0;
  color: $c-red;
  padding-left: 10px;
}

.dps-input *:focus {
  outline: none;
  border-color: inherit;
  -webkit-box-shadow: none;
  box-shadow: none;
}

.dps-button {
  height: 40px;
  min-height: 40px;
  border-radius: 3px;
  background-color: $c-black;
  padding: 0px 80px;
  border: 0;

  span {
    color: $c-white;
  }
}

.dps-select {
  color: #808080;
  padding-left: 12px;
  padding-right: 20px;
  margin-right: 20px;
  height: 30px;
  border-radius: 5px;
  border: 2px solid #9de79d;
  background-color: #ccf3cc;

}

div,
form,
input,
button {
  box-sizing: border-box;
}

@keyframes spinner {
  0% {
    transform: scale(0);
  }
  50% {
    opacity: 1;
  }
}

.spinner {
  display: inline-block;
  width: 20px;
  height: 20px;
  vertical-align: text-bottom;
  background-color: $c-white;
  border-radius: 50%;
  opacity: 0;
  -webkit-animation: spinner 0.75s linear infinite;
  animation: spinner 0.75s linear infinite;
}

.txt-red {
  color: $c-red;
}

.forgot-pass {
  display: block;
  width: max-content;
  background: unset;
  border: none;
  text-decoration: underline;
  font-size: 13px;
  margin-left: auto;
  margin-bottom: 20px;
}

.background {
  background: $dark_neutral;
  justify-content: center;

  .card {
    align-self: center;
    width: 400px;
    height: 500px;
    padding: 60px;
    border-radius: 10px;
    border-width: 0px;
    margin: 0 auto;
    -webkit-backdrop-filter: blur(10px);
    backdrop-filter: blur(10px);
    background-color: $light;

    .logo{
      margin-bottom: 36px;
    }

    label {
      display: block;
      width: 100%;
      text-align: left;
      margin-bottom: 4px;
    }
  }

  .privacy_support{
    margin: 16px auto 0;
    a {
      text-align: center;
      color: $light;
      text-decoration: none;
      font-size: 13px;
    }
  }

  div {
    margin-bottom: 24px;
    align-self: stretch;
    position: relative;
  }

  .alert-text {
    margin: 20px;
  }

  .login-alert-text {
    margin: 0px 20px 5px;
  }

  .language-switch {
    position: fixed;
    top: 10px;
    right: 10px;
  }
}

.btn-reset-password {
  // style={{
  //   color: "#fff",
  //   textTransform: "uppercase",
  //   fontSize: "13px",
   
  // }}
  color: #fff;
  text-transform: uppercase;
  font-size: 13px;
  font-family: 'Inter', sans-serif;
  font-weight: 300;

}

// responsive mobile 
@media (max-width: 845px ) { 
  .background .card {
    width: 360px;
    padding: 20px;
  }
  .login-font-size-revert {
    font-size: 16px;
  }  
}
@media (max-width: 280px ) { 
  .background .card {
   
    width: 270px;
    padding: 15px;
   
}
  
}


