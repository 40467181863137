// common list and detail

.charging, .non-charging{
  color: $info !important;
}
.ready, .opened, .landed{
  color: #36B25F !important;
}
.closed{
  color: #77706E !important;
}
.opening, .closing{
  color: #E4197E !important;
}
.empty{
  opacity: 0.15;
}
.not-found{
  opacity: 0.5;
}
.disable{
  pointer-events: none;
  opacity: 0.4;
}

.now-flying{
  color: #E4197E !important;
  font: 700 12px 'Inter' !important;
  align-self: center;
  flex: 1;
}
.charging-empty{
  font: 700 15px 'Inter';
  line-height: 17px;
  margin: 0;
  color: rgba($dark_neutral, 0.7);
}
// end common

.image-preview {
  background: #3e3a3988;

  .modal-content {
    width: 100%;
    max-width: 100% !important;
    height: 90vh;
    position: relative !important;

    .modal-header {
      z-index: 999;
      position: absolute;
      top: 0;
      width: 100%;
    }

    .modal-body {
      position: absolute;
      padding: 1px;
      padding-top: 60px;
      height: 100%;
      top: 0px;
      left: 0px;
      width: 100%;
      z-index: 1;
    }
  }

  .gallery {
    width: 100%;
    height: 100%;
    display: flex;
    flex: 1;
    flex-direction: column;

    .img-area {
      width: 100%;
      height: 0;
      flex: 1;
      display: block;

      .img-preview {
        width: 100%;
        height: 100%;
        object-fit: contain;
      }
    }

    .gallery-slide {
      width: 100%;
      height: auto;
      padding: 20px;
      display: flex;
      flex-direction: row;
      flex-wrap: nowrap;
      overflow-x: auto;
      overflow-y: hidden;
      min-height: 120px;

      .img-item {
        --width: 160px;
        width: var(--width);
        height: calc(var(--width) * 9 / 16);
        display: inline-block;
        background-repeat: no-repeat;
        background-size: cover;
        margin-bottom: 6.6px;
      }
      .active{
        border: 3px solid $dark_neutral;
      }
    }
  }

  .actions{
    padding: 0 30px;
    position: absolute;
    top: 50%;
    left: 0;
    right: 0;
    display: flex;
    justify-content: space-between;
    align-items: center;
    z-index: 1;

    button{
      width: 30px;
      height: 30px;
      background: $dark_neutral;
      border-radius: 100rem;
      border: 0;
      display: flex;
      justify-content: center;
      align-items: center;

      &:first-child{
        transform: rotate(180deg);
      }
    }
  }
}

.image-preview .modal-dialog {
  justify-content: center;
  margin: 5vh auto;
  max-width: 90vw;
}

.modal-dialog.modal-fullscreen {
  width: 100vw;
  max-width: none;
  height: 100%;
  margin: 0;

  &:is(.modal.fade *) {
    transition: none;
  }
}

.full-map-view .modal-content {
  width: 96vw;
  height: 96vh;
  padding: 22px 20px;
  border-radius: 10px;
  margin: auto;
  position: relative;
  overflow-y: hidden;

  .modal-header {
    width: 100%;
    margin-bottom: 20px;
    display: flex;
    padding: 0;
    border: 0;
    
    .modal-title{
      display: flex;
      align-items: center;
      
      h3{
        display: flex;
        align-items: center;
        font: 500 16px 'Inter', sans-serif;
        margin-right: 30px;
        
        svg{
          margin-right: 10px;
        }
      }
    }
    
    button{
      width: 40px;
      height: 40px;
      background: transparent;
      border: 0;
      border-radius: 5px;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      cursor: pointer !important;
      z-index: 9999;
    }
  }

  .modal-body {
    padding: 0px;
    width: 100%;
    height: 100%;

    .play-full_screen{
      width: 100%;
      padding: 18px 0;
      display: flex;
      justify-content: center;
      align-items: flex-end;
      background: $border;
      border-radius: 10px;
      position: relative;
    }
  }

  .image-map-view {
    padding: 0;
    margin: 0;

  }

  .image-map-image {
    //max-height: 96vh !important;
  }
}

.icon-fullscreen {
  width: 32px !important;
  height: 32px;
  background-color: $border;
  border-radius: 7.5px;
  position: absolute;
  bottom: 15px;
  right: 15px;
  display: flex;
  flex-direction: column;
  align-content: center;
  align-items: center;
  justify-content: center;
  z-index: 10;
  cursor: pointer;

  svg {
    color: $dark_neutral;
    width: 50%;
  }
}

.drone-tracking-charging {
  display: none !important;
}

// Start custom card content

.drone-status {
  margin-top: auto;

  .drone-status-area {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: row;
  }

  .no-data {
    text-align: center;
    padding: 20px 0;
    width: 100%;
    background-color: white;
    border-radius: 10px;
    margin: 0px;
  }

  .drone-list {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    gap: 12px;
    flex-direction: row;

    .device-item{
      width: 241px;
      min-height: 206px;
      display: flex;
      padding: 7px;
      flex-direction: column;
      align-items: flex-start;
      gap: 5px;
      border-radius: 8px;
      border: $card-border;
      box-shadow: $card-shadow;
      position: relative;
      cursor: pointer;
      flex-shrink: 0;

      &.list {
        background-color: $white;
      }

      .device {
        display: flex;
        width: 100%;
        padding: 10px 12px;
        align-items: center;
        gap: 8px;
        position: relative;

        &:is(.list *) {
          height: 91px;
        }

        .device-icon-tag {
          width: 62px;
          height: 62px;
          flex-shrink: 0;
          border-radius: 8px;
          mix-blend-mode: multiply;
        }

        .device-info {
          display: flex;
          flex-direction: column;
          align-items: flex-start;
          gap: 1px;
          flex: 1 0 0;

          .type {
            color: $dark_neutral;
            font: 500 14px Inter;
            margin: 0;
          }

          .name {
            color: $dark_neutral;
            font: 700 16px Inter;
            line-height: 20px; /* 125% */
            margin: 0;
          }

          .status{
            color: $dark_neutral;
            font: 700 12px/15px Inter;
            margin: 0;
          }
        }
        
        .no-drone{
          line-height: 92px !important;
        }

        .indicator {
          display: block;
          width: 6px;
          height: 6px;
          border-radius: 100rem;
          background: #CAC5C4;
          position: absolute;
          top: 14.5px;
          left: 12px;
          z-index: 1;

          &.active {
            background: #89E17F;
          }
        }
      }

      .dash {
        width: 100%;
        height: 1px;
        margin: 0;
        background: #CAC5C4;
        opacity: 0.4;
        border: none;
      }
  
      .drone {
        height: 92px;

        .status-battery{
          width: 100%;
          display: flex;
          align-items: center;
          justify-content: space-between;
          
          .battery-icon {
            display: flex;
            justify-content: flex-end;
            align-items: center;
            flex: 1 0 0;

            span {
              color: $dark_neutral;
              font: 600 9px Inter;
            }

            svg {
              width: 21px;
              height: 11px;
              flex-shrink: 0;
            }
          }
        }

        .time-schedule{
          display: flex;
          align-items: center;
          gap: 3px;
          padding-top: 3px;

          svg {
            width: 13px;
            height: 13px;
          }

          .time{
            color: $dark_neutral;
            font: 400 11px Inter;
            line-height: 12px;
          }
        }

        .no-drone{
          color: #AAA3A2;
          font: 600 12px Inter;
          margin: 0 auto;
        }
      }

      .flying-info {
        display: flex;
        padding: 0px 12px 0px 20px;
        flex-direction: column;
        align-items: flex-start;
        gap: 5px;
        align-self: stretch;

        & > p{
          margin: 0;
        }

        .plan-flying{
          color: $dark_neutral;
          font: 600 12px Inter;
        }

        .current-time-status{
          color: $dark_neutral;
          font: 500 11px Inter;
        }

        .msg{
          color: #36B25F;
          font: 600 12px Inter;
          display:-webkit-box;
          -webkit-line-clamp:3;
          -webkit-box-orient: vertical;
          overflow: hidden;
          text-overflow: ellipsis;
          word-break: break-word;
        }
      }

      .overlay {
        width: 100%;
        height: 100%;
        position: absolute;
        top: 0;
        left: 0;
        border-radius: inherit;

        &:hover{
          background-color: $overlay-hover;
          box-shadow: $card-shadow-hover;
          transition: 0.2s ease;
        }  
      }
    }
  }

  .drone-grid {
    margin: 4px;
    border-radius: 5px;
    border: #6c7993 solid 1px;
    background-color: #e5efdb;

    min-width: fit-content;
    cursor: pointer;

    .rem07 {
      font-size: 0.7rem;
    }
  }

  .drone-detail-status{
    .drone-detail-status-info {
      .select-filght-plan{
        .kiriha-mode {
          width: 100%;
          margin-top: 12px;
          display: flex;
          align-items: center;
          gap: 6px;

          .kiriha-icon{
            margin-left: 6px;
          }

          .disable-kiriha-mode, .no-data-kiriha{
            color: $dark_neutral;
            font: 400 12px Inter;
          }
        }
      }
    }

    .drone-detail-status-content {
      .card-content{
        .streaming{
          .video-streaming-wrap{
            width: 100%;
            height: 100%;
            position: relative;
            display: flex;
            justify-content: center;
            align-items: center;

            .loading {
              position: absolute;
              top: 0;
              right: 0;
              bottom: 0;
              left: 0;
              border-radius: 0;
            }

            & > video {
              width: 100%;
              height: 100%;
              object-fit: contain;
              background: #000;
            }

            .err-msg{
              position: absolute;
              left: 0;
              top: 0;
              width: 100%;
              height: 100%;
              display: flex;
              align-items: center;
              text-align: center;
              justify-content: center;
              font-size: 16px;
              font-weight: bold;
              color: white;
              pointer-events: none;
              padding: 20px;
              box-sizing: border-box;
              text-shadow: 0 0 5px black;
            }
          }
        }
      }
    }
  }

  .border-red {
    border-radius: 15px;
    margin-top: 4px;
    box-shadow: 0 0 15px 5px #f49494;
  }

  .drone-detail {
    margin-left: 20px;
    height: 100%;
    width: 100%;
    background-color: $c-white;
    display: flex;
    flex-direction: row;
    border-radius: 10px;
    padding: 10px;
    overflow-y: auto;
  }
  
  .dr-text {
    padding-top: 12px;
    color: $c-black;

    .alert-label {
      margin-right: 10px;
      font-weight: bold;
    }
  }

  .drone-tracking {
    width: 100%;
    margin-left: 20px;
    min-width: 300px;

    display: flex;
    flex-direction: column;

    .scroll-bar {
      display: flex;
      min-height: min-content;
    }

    .drone-line {
      // display: inline-table;
      // border-left: 2px solid $c-yellow;
      // margin-bottom: 30px;
      // margin-left: 50px;
    }

    .image-map-view {
      margin-top: 0px !important;

      .image-map-image {
        //max-height: 80vh !important;
      }
    }
  }

  .tracking-item {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    margin-top: 60px;
    height: 40px;
    position: relative;

    .break {
      flex-basis: 100%;
      height: 0;
    }

    .flight-indicator {
      position: absolute;
      top: 45px;
      left: -20px;

      #Icon_material-flight {
        transform: rotate(180deg);
      }
    }

    .flight-indicator-on-marker {
      top: -10px;
    }

    .flight-indicator-return {
      top: -50px !important;
    }

    .icon {
      width: 20px;
      height: 20px;
      border-radius: 30px;
      margin-left: -10px;
      display: block;
      background-color: $c-green;
    }

    .message {
      font-size: 11px;
      color: #707070;
      max-width: 200px;

      div {
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }
    }

    // .time.bold{
    //     padding-left: 30px;
    //     margin: 0 !important;
    // }
    div {
      margin: 0 0 0 28px;
    }

    .icon,
    .link {
      display: flex;
      align-items: center;

      svg {
        display: block;
        margin: 0 auto;
      }
    }

    .time.bold {
      padding-left: 30px;
      margin: 0;
    }

    &.status-take-off,
    &.status-landing {
      .icon {
        background-color: $c-yellow;
        margin-left: -20px;
        width: 40px;
        height: 40px;
      }

      p {
        color: $c-yellow;
        font-weight: bold;
        line-height: 40px;
      }
    }

    &.status-take-off {
      margin-top: 0px;

      .id {
        margin-left: 30px;
        width: 50px;
      }
    }

    &.status-pass {
      .icon {
        margin-top: 5px;
      }

      .id {
        margin-left: 40px;
        width: 50px;
      }

      p {
        color: $c-green;
        line-height: 30px;
      }
    }

    &.status-landing {
      .id {
        margin-left: 30px;
        width: 50px;
      }
    }

    &.status-pending {
      .icon {
        background-color: $c-gray;
      }

      .id {
        margin-left: 40px;
        width: 50px;
      }

      p {
        color: $c-gray;
      }
    }

    .link {
      width: 30px;
      height: 30px;
      margin-left: 100px;
      border-radius: 30px;
      background-color: $c-green;
    }
  }
}

// End custom card content

.drone-line {
  padding-left: 40px;
  padding-top: 25px;
  padding-bottom: 30px;
  overflow-y: auto;
  flex-grow: 1;

  .tracking-item {
    margin-top: 0px;
    padding-bottom: 74px;

    border-left: 2px solid $c-yellow;
    height: 20px;

    .icon {
      margin-top: 0px !important;
    }

    p {
      line-height: 20px !important;
    }

    .link {
      margin-top: -5px !important;
    }
  }

  .tracking-item:last-child {
    margin-top: 0px;
    margin-bottom: 0px !important;
    border-left: 0;

    .flight-indicator {
      top: -10px;
    }
  }
}

.btn-show-history {
  margin-top: 10px;
  color: #ffffff;
  background-color: $c-gray;
  border: 1px solid $c-gray;
  font-size: 1rem;
  text-align: center;
  padding: 0 15px;
  min-height: 30px;
  height: 30px;
  border-radius: 20px;
  flex: 0;
}

.alert-all-area {
  width: 100%;
  background: $white;
  overflow-y: auto;
  border-radius: 10px;
  border: $card-border;

  tr{
    border-bottom: 1px solid $border;
    display: table;

    &:last-child{
      border-bottom: 0;
    }
  }
}

.alert-close {
  position: absolute;
  top: 10px;
  right: 20px;
  cursor: pointer;
}

.history-area {
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  min-height: 0;
  align-items: start;
  padding-bottom: 20px;

  .history-area-header{
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-wrap: wrap;
    margin-bottom: 12px;

    h3{
      font: 700 14px 'Inter', sans-serif;
      margin: 20px;
    }
  }

  .empty{
    width: 100%;
    font: 700 14px 'Inter', sans-serif;
  }

  td:first-child{
    font: 700 14px 'Inter', sans-serif;
  }
  td:last-child{
    font: 400 14px 'Inter', sans-serif;
    white-space: nowrap;
  }
  
}

.fit-remaining-height {
  background-color: #f2f2f2;
  border: 1px solid #a6a6a6;
  padding: 8px;
  margin-top: 8px;
  border-radius: 10px;
  flex: 1;
  min-height: 0;
  overflow-y: auto;
  width: 100%;
}

.alert-label-all {
  font-size: 16px;
  margin-right: 10px;
}

.txt-alert-history {
  text-align: left !important;
  font-size: 16px;
  color: #4B4B4B;
  font-weight: bold;
}

.grid-drone {
  overflow-x: hidden;
  overflow-y: auto;
}

.image-title-font {
  font: 500 16px 'Inter', sans-serif;
  display: flex;
  align-items: center;

  span{
    display: inline-block;
    width: 20px;
    height: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    font: 700 10px 'Inter', sans-serif;
    color: $primary;
    background: $dark_neutral;
    border-radius: 100rem;
    margin-right: 10px;
  }
}

.action-mobile-btn {
  display: none;
}

.title-status-mobile {
  display: none;
}

.check_is_tablet {
  // check tablet
  
  @media screen and (max-width: 1181px) and (min-width: 720px) {
    .check-mobile-status-takeoff {
      justify-content: space-between;
  
    }
  
    .drone-home{
      .drone-status-area{
        padding: 30px 20px 20px 20px;
      }
    }
  }
}

// Mobile
#root .dps-mobile-view {
  .drone-status {
    .drone-list {
      padding: 0 3px;
      
      &::-webkit-scrollbar{
        display: none;
      }

      .device-item {
        width: 100%;
      }
    }

    .drone-detail-status{
      .drone-detail-status-info {
        .drone{
          position: relative;

          .form-option{
            position: absolute;
            top: 32px;
            right: 8px;
          }
        }
      }
    }
  }
}