.tab-menu {
  height: 100%;
  width: 100%;
  background-color: $dark_neutral;
  justify-content: space-between;
  padding-bottom: 24px;

  span {
    white-space: nowrap;
    text-overflow: ellipsis;
    color: currentColor;
  }

  button:focus {
    box-shadow: none;
  }

  .mheader{
    overflow-y: auto;

    &::-webkit-scrollbar{
      display: none;
    }

    .user-option {
      position: sticky;
      top: 0;
      z-index: 1;
      padding: 20px 14px;
      display: flex;
      align-items: center;
      gap: 8px;
      align-self: stretch;
      background: $dark_neutral;

      .image {
        width: 36px;
        height: 36px;
        border-radius: 100rem;
      }

      .textAva{
        color: #fff;
        margin: 0;
        font-size: 12px;
        font-weight: 400;
        display:-webkit-box;
        -webkit-line-clamp:2;
        -webkit-box-orient: vertical;
        overflow: hidden;
        text-overflow: ellipsis;
        word-break: break-word;
        white-space: wrap;
      }
    }
  }
}

.avatar {
  padding: 26px 20px 0;
  background: $dark_neutral;
  display: flex;

  .image {
    max-width: 50%;
  }

  .textAva {
    font-size: 15px;
    color: $white;
  }
}

.choose-btn {
  width: 3px;
  height: 42px;
  background-color: $yellow;
}

.display-none {
  display: none;
}

.menu-item {
  width: 100%;
  border-radius: 0;
  height: 42px;
  line-height: 42px;
  box-sizing: border-box;
  display: block;
  padding-left: 42px;
  transition: background-color 250ms ease;
  position: relative;
  text-decoration: none;
  color: $white;

  svg {
    width: 21px;
    height: 21px;
    color: currentColor;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    left: 14px;
  }

  .icon-text {
    line-height: 20px;
    font: 500 13px 'Inter', sans-serif;
  }

  &.version {
    color: #d3d3d3;
    cursor: auto !important;
    font-size: 12px;
    padding-bottom: 0;
    padding-top: 0;
    height: auto;
  }

  &:hover {
    background-color: #ffffff14;
    color: $white;
  }

  &:active {
    color: $white;
  }
}

.menu-item.active {
  color: $yellow !important;
  background-color: #ffffff20
}

// Mobile
.dps-mobile-view{
  .dps-header-mobile{
    position: fixed;
    z-index: 9999;
    top: 0;
    left: 0;
    right: 0;

    display: flex;
    height: 68px;
    padding: 0px 20px;
    justify-content: space-between;
    align-items: center;
    flex-shrink: 0;
    align-self: stretch;
    background: $dark_neutral;

    .logo-mobile{
      width: 76px;
    }

    .form-fixed {
      position: fixed;
      top: 68px;
      right: 0;
      bottom: 0;
      left: 0;
      background: $dark_neutral;
      padding: 10px 0 24px;
    }

    .form-menu-mb{
      display: none;

      .tab-menu{
        width: 200px;
        margin-left: auto;
        padding: 0;
      }

      &.active {
        display: block;
      }
    }

    .form-setting-mb{
      padding: 10px 20px;
      background-color: $white;

      & > h3 {
        color: $dark_neutral;
        font-size: 20px;
        font-style: normal;
        font-weight: 600;
        line-height: 20px;
        margin-bottom: 30px;
      }

      .setting_mode_mobile{
        display: flex;
        justify-content: space-between;
        align-items: center;
      }
    }
  }
}