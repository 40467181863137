.top-0 {
  top: 0;
}

.z-index-3 {
  z-index: 3;
}

.opacity-50 {
  opacity: 0.5;
}

.opacity-70 {
  opacity: 0.7;
}

.btn-save {
  width: 30px;
  height: 30px;
  border-radius: 20px;
  border: none;
  display: inline-flex;
  flex-direction: row;
  align-items: center;
  background-color: $c-green;

  svg {
    margin: 0 auto;
  }
}

.btn-set-scale {
  position: absolute;
  right: 20px;
  bottom: 20px;
}


// Map Zone
.content-map-zone{
  width: 40%;
  padding-right: 10%;
  display: flex;
  flex-direction: column;

  .tab-title{
    font: 700 24px 'Inter', sans-serif;
    margin-bottom: 13px;
  }
  .tab-desc{
    margin-bottom: 28px;
    span{
      font: 400 14px 'Inter', sans-serif;
    }
  }
  .tab-from{
    margin-bottom: 96px;

    label{
      display: block;
      margin-bottom: 4px;
      font: 400 12px 'Inter', sans-serif;
    }

    .mask-tab-form{
      min-width: 50%;
      width: max-content;
      padding: 14px 12px;
      border: 1px solid $in_border;
      border-radius: 5px;
      cursor: pointer;

      span{
        pointer-events: none;
        user-select: none;
        font: 400 16px 'Inter', sans-serif;
      }
    }

    .mask-input{
      min-width: 50%;
      width: max-content;
      position: relative;
      border: 1px solid #000;
      border: 1px solid $in_border;
      border-radius: 5px;
      font-size: 14px;

      input{
        width: 100%;
        border: 0;
        padding: 14px 12px;
        border-radius: inherit;
        opacity: 0;
      }

      .custom-value {
        user-select: none;
        pointer-events: none;
        position: absolute;
        padding: 14px 12px;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;

        span{
          font-size: inherit;
          font: 400 16px 'Inter', sans-serif;
        }
      }

      input:focus, input:hover{
        opacity: 1;
      }
      input:focus + .custom-value span:first-child,
      input:hover + .custom-value span:first-child{
        opacity: 0;
      }
    }
  }
  .tab-actions{
    margin-top: auto;

    button{
      font-size: 14px;
      font-weight: 500;
      padding: 10px 22px;
      background: transparent;
    }

    .pre-tab{
      border: 0;
      font: 700 12px 'Inter', sans-serif;
    }
    .next-tab{
      border: 2px solid $tertiary;
      border-radius: 6px;
      font: 700 12px 'Inter', sans-serif;
      color: $tertiary ;
      min-width: 72px;
      min-height: 36px;
      line-height: 0;
    }
  }

  .marker_list{
    display: flex;
    flex-wrap: wrap;
    max-width: 100%;
    border: 1px solid $in_border;
    padding: 16px;
    
    .mask_marker{
      margin: 4px;
      padding: 3px;
      background: $border;
      border-radius: 50%;
      width: 21px;
    }
  }
}

.preview-map-zone{
  width: 60%;
  position: relative;
  overflow: hidden;

  .border-dark{
    border: 0 !important;
  }

  .direction-container{
    position: relative;
    z-index: 1;

    .temp-marker-drag{
      display: block;
      width: 15px;
      height: 15px;
      position: relative;
      opacity: 0;
      pointer-events: none;
      &::before{
        content: '';
        position: absolute;
        top: 0;
        left: -100%;
        width: 300%;
        height: 100%;
        background: $dark_neutral;
        border-radius: 2px;
        z-index: -1;
      }
      &::after{
        content: '';
        position: absolute;
        top: -100%;
        left: 0;
        width: 100%;
        height: 300%;
        background: $dark_neutral;
        border-radius: 2px;
        z-index: -1;
      }

      .arrow-left, .arrow-right, .arrow-up, .arrow-down {
        width: 15px;
        height: 15px;
        position: absolute;
        background: transparent;
        border-radius: 3px;
        display: flex;
        justify-content: center;
        align-items: center;
        background: $dark_neutral;
  
        &::before{
          content: '';
          position: absolute;
          width: 0;
          height: 0;
          border-radius: inherit;
        }
      }
  
      .arrow-left{
        top: 0;
        right: 100%;
        &::before{
          transform: translateX(-10%);
          border-top: 3.06px solid transparent;
          border-bottom: 3.06px solid transparent;
          border-right: 3.48px solid currentColor;
        }
      }
      .arrow-right{
        top: 0;
        left: 100%;
        &::before{
          transform: translateX(10%);
          border-top: 3.06px solid transparent;
          border-bottom: 3.06px solid transparent;
          border-left: 3.48px solid currentColor;
        }
      }
      .arrow-up{
        left: 0;
        bottom: 100%;
        &::before{
          transform: translateY(-10%);
          border-left: 3.06px solid transparent;
          border-right: 3.06px solid transparent;
          border-bottom: 3.48px solid currentColor;
        }
      }
      .arrow-down{
        left: 0;
        top: 100%;
        &::before{
          transform: translateY(10%);
          border-left: 3.06px solid transparent;
          border-right: 3.06px solid transparent;
          border-top: 3.48px solid currentColor;
        }
      }
    }

    .temp-marker-no-drag{
      position: absolute;
      top: 0;
      left: 0;
      pointer-events: none;
      user-select: none;
      transform: translate(-7.5px, -7.5px);
    }

    .marker_wrap{
      opacity: 0;

      &:active{
        opacity: 1 !important;
      }
      &:active ~ .temp-marker-no-drag{
        opacity: 0;
      }
    }

    &:nth-of-type(2){
      display: none;
    }
    
  }

  .drone-icon-in-map{
    position: relative;
    z-index: 9;

    .intro-banner-vdo-play-btn{
      width: 11px;
      height: 11px;
      transform: translate(-50%, -50%);
      background: #524E4D;
      position: relative;

      &::before{
        content: '';
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        width: 50%;
        height: 50%;
        border-radius: 50%;
        background: $danger;
      }
    }
  }

  .btn_rs_first-marker{
    position: absolute;
    bottom: 10px;
    right: 10px;
    z-index: 10;
    background: transparent;
    border: 2px solid #3E3A39;
    border-radius: 6px;
    padding: 4px 20px;
  }
}

.marker span svg{
  width: 9px;
}