.drone-detail-status {
  display: flex;
  height: 100%;
  flex: 1;
  max-width: 100%;

  .drone-detail-status-info {
    border-right: 1px solid #CAC5C4;
    display: flex;
    width: 235px;
    flex-direction: column;
    align-items: flex-start;
    flex-shrink: 0;
    align-self: stretch;
    background: $background;
    position: relative;

    .device-item {
      width: 100%;
      padding: 0;
      border-radius: 0;
      border: 0;

      .device {
        width: 100%;
        min-height: 82px;
        padding: 10px 12px;
        border-bottom: 1px solid #CAC5C4;
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: 8px;
        position: relative;

        .device-icon-tag {
          width: 62px;
          height: 62px;
          flex-shrink: 0;
          border-radius: 8px;
          mix-blend-mode: multiply;
        }

        .device-info-wrap {
          display: flex;
          align-items: center;
          width: 100%;
          gap: 8px;

          .device-info {
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            gap: 1px;
            flex: 1 0 0;
  
            .type {
              color: $dark_neutral;
              font: 500 14px Inter;
              margin: 0;
            }
  
            .name {
              color: $dark_neutral;
              font: 700 16px Inter;
              line-height: 20px; /* 125% */
              margin: 0;
            }
  
            .status{
              color: $dark_neutral;
              font: 700 12px Inter;
              margin: 0;
            }
          }
        }

        .indicator {
          display: block;
          width: 6px;
          height: 6px;
          border-radius: 100rem;
          background: #CAC5C4;
          position: absolute;
          top: 14.5px;
          left: 12px;
          z-index: 1;

          &.active {
            background: #89E17F;
          }
        }

        & > svg {
          position: absolute;
          top: 4px;
          left: 5px;
        }

        .btn-alert{
          position: absolute;
          top: 4px;
          right: 8px;
          width: 26px;
          height: 26px;
          border: 0;
          border-radius: 2px;
          padding: 0;
          background-color: transparent;
          display: flex;
          justify-content: center;
          align-items: center;
          transition: .25s linear;

          svg {
            pointer-events: none;
          }
          
          &.active {
            background: #E9E8F7;
          }

          &:hover {
            background: #E9E8F7;
          }
        }

        .msg{
          margin: 0;
          width: 100%;
          display:-webkit-box;
          -webkit-line-clamp:3;
          -webkit-box-orient: vertical;
          overflow: hidden;
          text-overflow: ellipsis;
          word-break: break-word;
          color: #000;
          font: 400 9px Inter;
          line-height: 11px; /* 122.222% */
        }
      }

      .drone {
        .status-battery{
          width: 100%;
          display: flex;
          align-items: center;
          justify-content: space-between;
          
          .battery-icon {
            display: flex;
            justify-content: flex-end;
            align-items: center;
            flex: 1 0 0;

            span {
              color: $dark_neutral;
              font: 600 9px Inter;
            }

            svg {
              width: 21px;
              height: 21px;
              flex-shrink: 0;
            }
          }
        }

        .time-schedule{
          display: flex;
          align-items: center;
          gap: 3px;

          svg {
            width: 13px;
            height: 13px;
          }

          .time{
            color: $dark_neutral;
            font: 400 11px Inter;
            line-height: 12px;
          }
        }

        .no-drone{
          line-height: 92px !important;
          color: #AAA3A2;
          font: 600 12px Inter;
          margin: 0 auto;
        }
      }

      .select-filght-plan {
        width: 100%;
        padding: 12px;

        .dps-select {
          width: 100%;
          height: auto;
          margin: 0;
          -webkit-appearance: none;
          appearance: none;
          border-radius: 3px;
          border: 1px solid #7168CA;
          background: #fff;
          padding: 0;
          position: relative;

          select {
            width: 100%;
            padding: 6.5px 10px;
            padding-right: 26px;
            -webkit-appearance: none;
            appearance: none;
            border: 0;
            border-radius: inherit;
            font: 400 12px Inter;
            line-height: 15px;
          }

          svg {
            width: 16px;
            height: 16px;
            position: absolute;
            top: 50%;
            transform: translateY(-50%);
            right: 4px;
          }
        }
      }

      .action {
        width: 100%;
        padding-bottom: 15px;
        position: relative;

        .btn-takeoff {
          width: 100px;
          height: 28px;
          padding: 0px 20px;
          display: flex;
          justify-content: center;
          align-items: center;
          border: 0;
          border-radius: 3px;
          background: #5F55C4;
          color: #fff;
          margin: 0 auto;
          font: 600 12px Inter;
          line-height: 15px;

          &:disabled {
            background: #AAA3A2;
            cursor: default;
          }
        }

        .btn-takeoff-memo {
          display: flex;
          width: 35px;
          height: 28px;
          padding-right: 1px;
          justify-content: flex-end;
          align-items: center;
          gap: 1px;
          border: 0;
          background-color: transparent;
          position: absolute;
          top: 0;
          right: 12px;

          &.active {
            border-radius: 3px;
            background: #D3D0F1;
          }

          &:disabled {
            opacity: 0.5;
            cursor: default;
          }
        }

        .input-flight-memo{
          display: block;
          width: calc(100% - 24px);
          padding: 8px 10px;
          border-radius: 5px;
          border: 1px solid #7168CA;
          margin: 10px auto 0;
          color: #000;
          font: 400 12px Inter;
          resize: none;
        }

        .msg-err{
          font: 400 11px Inter;
          color: $danger;
          line-height: 13px;
          position: absolute;
          bottom: 0;
          left: 50%;
          transform: translateX(-50%);
          white-space: nowrap;
        }
      }
    }

    .form-alert{
      max-height: 150px;
      display: flex;
      padding: 10px 10px 10px 12px;
      flex-direction: column;
      align-items: flex-start;
      gap: 7px;
      align-self: stretch;
      border-top: 1px solid rgba(233, 41, 87, 0.5);
      border-bottom: 1px solid #CAC5C4;
      background: rgba(233, 41, 87, 0.05);

      .form-alert-header{
        display: flex;
        justify-content: space-between;
        align-self: stretch;
        font: 500 11px Inter;

        .alert-icon{
          display: flex;
          align-items: center;
          gap: 2px;
          flex: 1 0 0;
          color: #E92957;
        }

        .clear-alert{
          display: flex;
          width: 60px;
          height: 22px;
          padding: 1px 0px;
          justify-content: center;
          align-items: center;
          border: 0;
          border-radius: 3px;
          background: #E92957;
          color: #FFF;
          font: inherit;
        }
      }

      .form-alert-content{
        flex: 1;
        overflow: auto;
        display: flex;
        flex-direction: column;
        align-self: stretch;
        gap: 7px;
      
        .alert-item{
          display: flex;
          flex-direction: column;
          
          p{
            margin: 0;
            font-family: Inter;
            font-style: normal;
            font-weight: 400;
            line-height: normal;
          }
        
          .time-alert{
            color: #3E3A39;
            font-size: 9px;
          }
          .text-alert{
            color: #E92957;
            font-size: 11px;
            word-break: break-all;
          }
        }
      }
    }

    .flight-plan {
      width: 100%;
      margin-top: 12px;
      text-align: center;
      color: $dark_neutral;
      font: 600 13px Inter;
      line-height: 21px; /* 161.538% */
    }

    .face-id {
      width: 100%;
      margin-bottom: 0;
      display: flex;
      align-items: center;
      gap: 16px;
      padding-inline: 18px 12px;

      span {
        color: $dark_neutral;
        font: 400 12px Inter;
        line-height: 15px; /* 125% */
      }
    }

    .flight-takeoff-memo{
      display: flex;
      width: 100%;
      padding: 8px 12px 10px;
      flex-direction: column;
      align-items: flex-start;
      gap: 6px;

      .text {
        color: var(--gray-01-base, #3E3A39);
        font: 400 10px Inter;
        line-height: 14px; /* 140% */
        margin: 0;
        word-break: break-all;

        strong {
          font-weight: 700;
        }
      }
    }

    .flight-process{
      flex: 1;
      width: 100%;
      margin-bottom: auto;
      position: relative;
      overflow: hidden;
      
      .wrap-result-tracking{
        height: 100%;
        padding: 12px 24px 12px 18px;
        overflow: auto;
        scroll-behavior: smooth;

        .status-process{
          height: max-content;
          position: relative;
  
          .marker-item {
            display: flex;
            align-items: center;
            gap: 6px;
            padding: 28px 28px 0 13px;
            position: relative;
  
            .line {
              display: block;
              width: 8px;
              height: 34px;
              background: $dark_neutral;
              position: absolute;
              top: -3px;
              left: 26px;
            }
  
            .marker-item-icon {
              width: 34px;
              height: 34px;
              border-radius: 100rem;
              background: $dark_neutral;
              display: flex;
              justify-content: center;
              align-items: center;
  
              span {
                color: #FFF;
                font: 700 13px Inter;
                line-height: 15px; /* 115.385% */
                z-index: 1;
              }
            }
  
            .marker-info{
              flex: 1;
              overflow: hidden;
  
              .time{
                white-space: nowrap;
                font-size: 12px;
                font-weight: 400;
                line-height: 20px;
  
                &.marker-arv {
                  padding-left: 13px;
                }
              }
  
              button {
                border: 0;
                padding: 0;
                background: transparent;
                position: absolute;
                right: 0;
                z-index: 10;
                display: flex;
                
                &.capture{
                  bottom: 5px;
                }
  
                &.capture-letf{
                  top: calc(100% + 14px);
                  transform: translateY(-50%);
                }
              }
            }
          }
  
          .marker-station{
            align-items: flex-start;
            padding: 0;
  
            .icon {
              width: 60px;
              height: 60px;
              flex-shrink: 0;
              border: 3px solid $dark_neutral;
              border-radius: 10px;
              overflow: hidden;
  
              img{
                width: 100%;
              }
            }
  
            .marker-station-info{
              display: flex;
              flex-direction: column;
              gap: 2px;
  
              p{
                margin: 0;
              }
  
              .name {
                color: $dark_neutral;
                font: 700 12px Inter;
                line-height: 15px; /* 125% */
              }
  
              .status {
                color: $dark_neutral;
                font: 700 11px Inter;
                text-transform: capitalize;
              }
            }
          }
  
          .marker-takeoff {}
  
          .marker-landing{
            padding-top: 28px;
          }
  
          .tracking-pass-item{
            .icon {
              position: relative;
  
              &::before{
                content: '';
                position: absolute;
                top: 0;
                left: 0;
                width: 100%;
                height: 100%;
                z-index: 1;
                border-radius: 7px;
                box-shadow: inset 0 0 0 2.5px #FCD13E;
              }
            }
  
            .marker-item-icon {
              position: relative;
  
              span {
                color: $dark_neutral;
              }
  
              &::before {
                content: '';
                position: absolute;
                width: 28px;
                height: 28px;
                background: #FCD13E;
                border-radius: inherit;
                z-index: 0;
              }
            }
  
            .line {
              z-index: 1;
              
              &::before {
                content: '';
                position: absolute;
                left: 50%;
                transform: translateX(-50%);
                width: 2px;
                height: 100%;
                background: #FCD13E;
              }
            }
  
            .marker-station-info {
              .time {
                color: $dark_neutral;
                font-size:12px;
                font-weight: 400;
                line-height: 20px;
              }
            }
  
            &.not-success-plan .line::before {
              display: none;
            }
          }
  
          .drone-process{
            position: absolute;
            top: 0;
            left: 30px;
            transform: translateX(-50%);
            width: 34px;
            height: var(--height);
            display: flex;
            justify-content: center;
            align-items: flex-end;
  
            .drone {
              width: var(--width);
              height: var(--width);
              background: #E64D5B;
              border: 3px solid $dark_neutral;
              border-radius: 100rem;
            }
          }
        }
  
        .no-process{
          display: flex;
          flex-direction: column;
          align-items: center;
          gap: 4px;
  
          p{
            color: #AAA3A2;
            margin: 0;
  
            &:first-child{
              font: 700 12px Inter;
              line-height: 15px; /* 125% */
            }
  
            &:last-child{
              font: 400 11px Inter;
              line-height: 15px; /* 125% */
            }
          }
        }
      }

      &.shadow-t::before {
        content: "";
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 12px;
        z-index: 2;
        background: linear-gradient(0deg, rgba(255, 255, 255, 0.00) 0%, #FFF 71.5%);
      }
  
      &.shadow-bt::after {
        content: "";
        position: absolute;
        bottom: 0;
        left: 0;
        width: 100%;
        height: 12px;
        z-index: 2;
        background: linear-gradient(rgba(255, 255, 255, 0.00) 0%, #fff 71.5%);
      }
    }

    .alert-history{
      position: absolute;
      left: 0;
      bottom: 0;
      right: 0;
      background: gray;
      z-index: 99;
      display: flex;
      padding: 10px 10px 10px 12px;
      flex-direction: column;
      align-items: flex-start;
      gap: 7px;
      align-self: stretch;
      border-top: 1px solid rgba(233, 41, 87, 0.50);
      background-color: #fff;
      overflow: hidden;

      .alert-history-header {
        width: 100%;
        display: flex;
        align-items: center;
        
        h5 {
          flex: 1;
          color: #E92957;
          font: 600 11px Inter;
          margin: 0;
        }

        button {
          border: 0;
          background: transparent;
          padding: 0;

          svg {
            width: 14px;
            height: 14px;
            color: #5F55C4;
          }
        }
      }

      .alert-history-body {
        width: 100%;
        flex: 1;
        display: flex;
        flex-direction: column;
        gap: 7px;
        overflow: auto;

        .alert-history-item{
          width: 100%;
          display: flex;
          flex-direction: column;
          
          span {
            color: $dark_neutral;
            font: 400 9px Inter;
          }
          p{
            color: #E92957;
            font: 400 11px Inter;
            margin: 0;
            word-break: break-all;
          }
        }
      }
    }
  }

  .drone-detail-status-content {
    flex: 1;
    overflow: hidden;
    padding: 6px;
    background-color: #e8e4e3;

    .panel-wrapper{
      .sub {
        display: flex;
        gap: 6px;
      }
    }
  }
}

.form-option {
  display: flex;
  width: 130px;
  padding: 5px 0px;
  flex-direction: column;
  align-items: flex-start;
  border-radius: 2px;
  border: 1px solid #A6A0DE;
  background: #FFF;
  position: fixed;
  z-index: 1;

  .item {
    display: flex;
    width: 100%;
    height: 20px;
    padding: 0px 9px;
    align-items: center;
    align-self: stretch;
    color: $dark_neutral;
    font: 400 10px Inter;
    cursor: pointer;
    transition: .25s linear;

    &:hover {
      background: #E9E8F7;
    }

    &.bg-reset{
      background: transparent;
      pointer-events: none;
      cursor: default;
    }
  }
}

.card-content {
  width: 100%;
  height: 100%;
  border: 1px solid #D4D4D4;
  border-radius: 5px;
  box-shadow: 0px 0px 2px 0px rgba(0, 0, 0, 0.05);
  background: #fff;
  align-self: stretch;
  overflow: hidden;
  display: flex;

  &.main-full-screen {
    border: none;
    border-radius: 0;
  }

  .pls-select{
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;

    p{
      color: #AAA3A2;
      font: 400 12px Inter;
      line-height: 21px;
      margin: 0;

      &:first-child {
        font-weight: 700;
      }
    }
  }

  .choose-marker {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    color: #AAA3A2;
    font: 400 12px Inter;
    line-height: 21px;
    margin: 0;
  }

  .preview-plan-map{
    width: 100%;
    height: 100%;
    display: flex;
    position: relative;

    .preview-plan-map-img{
      height: 100%;
      width: 0;
      flex: 1;
      overflow: hidden;

      .status-map-view{
        width: 100%;
        height: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        position: relative;

        .loading{
          position: absolute;
          top: 0;
          left: 0;
          right: 0;
          bottom: 0;
          z-index: 1;
        }

        .status-map-view-wrapper {
          position: relative;
          width: max-content;
          height: max-content;

          & > img {
            object-fit: contain;
          }

          .marker-item{
            flex-shrink: 0;
            position: absolute;
            left: 0;
            top: 0;

            .marker-icon{
              transform: translate(-50%, -50%);
            }
          }
        }
      }

      .not-setting {
        color: #AAA3A2;
        font: 700 14px Inter;
        margin: 0;
        height: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
      }
    }

    .preview-plan-map-info {
      width: 127px;
      height: 100%;
      overflow-y: auto;
      display: flex;
      padding: 12px 18px 12px 12px;
      flex-direction: column;
      align-items: flex-start;
      gap: 10px;
      align-self: stretch;

      .preview-plan-map-info-item {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        gap: 3px;
        align-self: stretch;
        color: #000;

        p{
          font: 500 11px Inter;
          margin: 0;
        }

        span {
          font: 400 10px Inter;
        }
      }
    }
  }

  .preview-sensor {
    height: 100%;
    position: relative;
    display: flex;
    flex-direction: column;
    padding: 14px;

    .no-sensor{
      color: #AAA3A2;
      font: 700 12px Inter;
      line-height: 15px; /* 125% */
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%,-50%);
    }

    .preview-sensor-sub-table {
      margin-top: 20px;
      flex: 1;
      overflow: auto;
      
      .preview-sensor-sub-table-wrapper {
        width: max-content;

        .sub-table-header{
          min-width: max-content;
          display: flex;
          align-items: center;
          gap: 16px;
          position: sticky;
          top: 0;
          z-index: 2;
          background: #fff;
          
          .sub-table-row{
            width: 100%;

            .unit {
              height: 15px;
            }

            .time {
              color: $dark_neutral;
              font: 600 12px Inter;
              height: 15px;
              line-height: 15px; /* 125% */
            }
          }
        }
  
        .sub-table-body{
          width: max-content;
        }

        .sub-table-row{
          min-width: max-content;
          display: flex;
          align-items: center;
          gap: 16px;
          padding-block: 3px;
          border-bottom: 1px solid #EFECEC;

          .unit {
            position: sticky;
            left: 0;
            background: #fff;
            flex: 1;
            min-width: 80px;
            max-width: 150px;
            margin-right: auto;
            color: $dark_neutral;
            font: 600 11px Inter;
            display:-webkit-box;
            -webkit-line-clamp:1;
            -webkit-box-orient: vertical;
            overflow: hidden;
            text-overflow: ellipsis;
            word-break: break-word;
          }
          .time, .val {
            width: 60px;
            text-align: center;
            color: $dark_neutral;
            font: 400 11px Inter;
            display:-webkit-box;
            -webkit-line-clamp:1;
            -webkit-box-orient: vertical;
            overflow: hidden;
            text-overflow: ellipsis;
            word-break: break-word;
          }
        }
      }
    }
  }

  .streaming{
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    
    iframe{
      width: 100%;
      max-height: 100%;
      aspect-ratio: 16 / 9;
      pointer-events: none;
    }
  }

  .form-setting {
    width: 166px;
    display: flex;
    padding: 10px 16px 14px 18px;
    flex-direction: column;
    align-items: flex-start;
    gap: 14px;
    background: red;
    border: 1px solid #7168CA;
    border-radius: 4px;
    background: #fff;
    position: fixed;
    z-index: 1;

    .form-setting-header {
      width: 100%;
      display: flex;
      justify-content: space-between;
      align-items: center;

      h5{
        color: $dark_neutral;
        font: 600 12px Inter;
        margin: 0;
      }

      .switch-setting{
        display: flex;
        height: 24px;
        align-items: center;
        flex-shrink: 0;
        background: #E9E8F7;
        border-radius: 3px;
        overflow: hidden;

        button {
          display: flex;
          width: 50px;
          padding: 0px 9px;
          justify-content: center;
          align-items: center;
          gap: 3px;
          align-self: stretch;
          color: #5F55C4;
          font: 400 10px Inter;

          &.active {
            color: #fff;
            background: #5F55C4 !important;
          }
        }
      }

      button{
        display: flex;
        justify-content: center;
        align-items: center;

        svg{
          width: 13px;
          height: 13px;
        }
      }
    }

    .form-setting-body {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      gap: 10px;
      align-self: stretch;
      margin-bottom: 6px;

      .form-item{
        width: 100%;
        display: flex;
        align-items: center;
        gap: 10px;

        label {
          flex: 1;
          color: $dark_neutral;
          font: 400 11px Inter;
          text-align: left;
        }

        input {
          width: 70px;
          height: 24px;
          border: 1px solid #7168CA;
          border-radius: 3px;
          padding: 4px 6px;
          font: 400 11px Inter;
        }
      }
    }

    .form-setting-footer {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: flex-start;
      gap: 12px;
    }
  }

  &.main{
    flex: 1;
    overflow: hidden;

    .sensors-result{
      .sensors-result-header{
        margin: 0;
        padding: 7px 12px;

        .actions{
          gap: 8px;
          position: relative;

          .btn-setting{
            border: 0;
            background: transparent;
            padding: 0;
            width: 26px;
            height: 26px;
            display: flex;
            justify-content: center;
            align-items: center;
            border-radius: 3px;

            &.active {
              background: #E9E8F7;
            }
          }

          .btn_csv {
            margin: 0;
          }

          .switch-sensor-view{
            flex-direction: row-reverse;
          }

          .form-setting{
            position: absolute;
            z-index: 1;
            top: 0;
            right: calc(100% + 2px);

            .form-setting-header {
              button{
                border: 0;
                background: transparent;
                display: flex;
                justify-content: center;
                align-items: center;
                padding: 0;

                svg{
                  width: 13px;
                  height: 13px;
                }
              }
            }
          }
        }
      }
    }

    .images-wrapper{
      padding: 4px 16px;
      gap: 10px;
      height: 100%;
    }
  }

  .preview-schedule{
    width: 100%;
    height: 100%;
    padding: 16px 2px 16px 18px;
    display: flex;
    flex-direction: column;
    gap: 12px;
    overflow: hidden;

    .preview-schedule-header {
      .preview-title{
        display: flex;
        align-items: center;
        gap: 8px;
        margin: 0;
        color: $dark_neutral;
        font: 700 13px Inter;
        white-space: nowrap;

        svg{
          width: 16px;
          height: 16px;
        }
      }
    }

    .preview-schedule-content{
      flex: 1;
      overflow: auto;

      .table-preview{
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        gap: 5px;
        margin-right: 9px;
        
        .table-row {
          display: flex;
          padding: 6px 8px;
          align-items: center;
          gap: 10px;
          align-self: stretch;
          cursor: pointer;

          .table-col{
            min-width: 50px;
            color: #000;
            font: 400 12px Inter;
            display:-webkit-box;
            -webkit-line-clamp:1;
            -webkit-box-orient: vertical;
            overflow: hidden;
            text-overflow: ellipsis;
            word-break: break-word;

            &:first-child {
              width: 105px;
              flex-shrink: 0;
            }

            &:nth-child(2) {
              flex: 1;
              font-weight: 500;
            }

            &:nth-child(3) {
              flex: 1;
              font-weight: 500;
            }
          }

          &:hover {
            background: #E4E2F3;
            border-radius: 4px;
          }
        }
      }
    }
  }

  .preview-sensor{
    width: 100%;

    .preview-sensor-actions {
      position: absolute;
      top: 7px;
      right: 7px;
      height: 23px;
      display: flex;
      align-items: center;
      gap: 4px;

      button {
        border: 0;
        background: transparent;
        padding: 0;
        cursor: pointer;
      }

      .btn-setting {
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 1.5px;
        border-radius: 3px;
        position: relative;
        
        svg{
          width: 20px;
          height: 20px;
        }

        &.active {
          background: #E9E8F7;
        }
      }

      .switch-btn-chart{
        height: 100%;
        display: flex;
        align-items: center;
        background-color: #E9E8F7;
        border-radius: 3px;
        overflow: hidden;
        
        button{
          height: 100%;
          padding: 0 9px;
          display: flex;
          justify-content: center;
          align-items: center;
          color: #5F55C4;

          &.active {
            color: #fff;
            background: #5F55C4;
          }
        }
      }
    }

    .sensor-chart{
      overflow: hidden;

      .chart{
        height: 100%;

        .legend{
          min-width: unset;
          max-width: 120px;
          overflow: auto;
          padding-left: 0;
          margin: 36px 5px 0 10px;
          position: sticky;
          top: 36px;

          .legend-list {
            width: max-content;
          }
        }
      }
    }
  }

  &:not(.main) {
    .kiriha-label {
      width: 90px;
      height: 60px;

      svg {
        width: 100%;
        height: 100%;
      }
    }
  }
}

.main-actions{
  display: flex;
  width: 50px;
  padding: 5px 1px 10px 0;
  flex-direction: column;
  align-items: center;
  align-self: stretch;
  border-left: 1px solid #D4D4D4;
  position: absolute;
  top: 0;
  right: 0;
  overflow-y: auto !important;

  &:is(.panel.main:has(.streaming) ~ *) {
    width: 49px;
    border-left: none;
  }

  &.main-actions-full-screen {
    padding-right: 0;
  }

  .actions{
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    flex: 1;

    button {
      padding: 0;
      border: 0;
      background: transparent;
      display: flex;
      justify-content: center;
      align-items: center;
      color: #5F55C4;
      transition: .1s linear;

      &:hover {
        background: #E9E8F7;
      }

      &.active{
        color: #fff;
        background: #5F55C4;
      }

      &.btn-full-screen {
        width: 32px;
        height: 32px;
        border-radius: 4px;

        svg {
          width: 22px;
          height: 22px;
        }
      }

      &.btn-stream,
      &.btn-images,
      &.btn-map,
      &.btn-sensor {
        width: 100%;
        height: 40px;

        svg {
          width: 26px;
          height: 26px;
        }
      }
    }

    .btn-stream{
      margin-top: 10px;
    }
  }

  .flight-height{
    margin-top: 10px;
  }
}

.blink {
  animation: blinker 1.5s ease infinite;
}

@keyframes blinker {
  50% {
    opacity: 0;
  }
}

.main-full-screen {
  position: fixed !important;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 100;

  .marker-item{
    .device_tracking-marker {
      & > svg {
        height: 18.22px !important;
        width: 18.22px !important;
      }

      span > svg {
        height: 7.89px !important;
        width: 9.11px !important;
      }

      .flight-next{
        left: -14px !important;

        & > svg {
          height: 45.56px !important;
          width: 45.56px !important;
        }

        span {
          font-size: 23px !important;
        }
      }
    }
  }
  
  .intro-banner-vdo-play-btn{
    height: 24px !important;
    width: 24px !important;

    & > svg {
      height: 24px !important;
      width: 40px !important;
    }

    .ripple {
      height: 56px !important;
      width: 56px !important;
    }
  }
}

.main-actions-full-screen{
  position: fixed !important;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  z-index: 101;
  height: unset !important;
}

// Mobile
#root .dps-mobile-view {
  .drone-detail-status{
    flex-direction: column;
    height: max-content;
    flex: unset;
    
    .drone-detail-status-info {
      width: 100%;
      background: #fff;

      .device-item{

        .select-filght-plan{
          padding-bottom: 16px;
          .dps-select{
            select {
              padding: 10.5px 10px;
            }
          }
        }

        .action {
          .btn-takeoff{
            width: 120px;
            height: 34px;
          }

          .btn-takeoff-memo{
            width: 41px;
            height: 34px;

            & > svg {
              flex-shrink: 0;

              &:last-child{
                width: 26px;
                height: 26px;
              }
            }
          }
        }
      }

      .flight-process{
        flex: unset;
        width: 100%;
        max-height: 328px;
        overflow-y: auto;
        border-bottom: 1px solid #CAC5C4;

        .wrap-result-tracking{
          padding: 12px 18px;
        }
      }

      .alert-history{
        min-height: 328px;
        box-shadow: 0 1px 2px $dark_neutral;
      }
    }

    .drone-detail-status-content {
      padding: 12px 0 50px;
      background: #F5F5F5;
      flex: unset;
      display: flex;
      flex-direction: column;
      gap: 12px;
      
      .card-content{
        height: auto;
        border-radius: 0;
        border: 0;
        border-bottom: 1px solid #CAC5C4;

        .preview-schedule{
          max-height: 178px;
        }

        .preview-sensor{
          .no-sensor{
            position: static;
            transform: unset;
            text-align: center;
          }
        }

        .preview-plan-map-info{
          display: none;
        }

        .pls-select {
          padding: 12px 0;
        }

        .choose-marker{
          padding: 10px;
        }
      }

      .drone-detail-status-content-wrap-mb {
        width: 100%;
        display: flex;
        flex-direction: column;
        gap: 10px;

        .main-actions{
          width: 100%;
          height: 36px;
          position: sticky;
          padding: 0 10px;
          background: $white;
          z-index: unset;
          order: 0;
          margin-bottom: -10px;

          .actions{
            flex-direction: row;
            justify-content: flex-end;
            height: 100%;
            gap: 8px;

            button {
              width: 32px;
              padding: 6px;
              height: 100%;
              margin: 0;

              & > svg {
                flex-shrink: 0;
              }
            }
          }
        }

        .card-content{
          order: 2;

          .preview-sensor{
            padding: 0 10px;

            .preview-sensor-sub-table {
              padding-bottom: 10px;
            }
          }
          .sensors-result{
            .chart{
              flex-direction: column;

              #legend-container {
                width: 100%;
                max-width: unset;
                margin: 0;
                margin-top: 12px;
                padding: 0 30px;
                
                .legend {
                  margin: 0;
                  max-width: unset;
                }
              }

              .normalize {
                width: max-content;
                display: flex;
                flex-direction: column;
                gap: 4px;
                margin: 6px 30px 12px auto;

                span {
                  color: $dark_neutral;
                  font: 400 12px Inter;
                }
              }
            }
          }
          .preview-sensor-actions{
            padding: 10px 30px 12px;
            position: relative;
            top: 0;
            right: 0;
            height: 54px;
            gap: 10px;
            justify-content: flex-end;

            .switch-btn-chart{
              button {
                width: 60px;
              }
            }

            .form-setting{
              position: absolute;
              bottom: 14px;
              right: 185px;
            }
          }
          .btn-dataset{
            margin-right: auto;
            display: flex;
            padding: 6px 34.5px;
            justify-content: center;
            align-items: center;
            border-radius: 3px;
            background: transparent;
            border: 1px solid #5F55C4;
            color: #5F55C4;
            font-size: 13px;
            font-weight: 400;
            line-height: 20px; /* 153.846% */
          }

          &.active {
            order: 1;

            .sensors-result {
              flex-direction: column-reverse;

              .sensors-result-header {
                margin: 0;
                padding: 12px 30px;

                .actions {
                  flex: 1;
                  margin: 0;
                  justify-content: flex-end;
                  gap: 10px;
                  position: relative;

                  .switch-sensor-view button {
                    width: 60px;
                  }

                  .btn-setting{
                    width: 23px;
                    height: 23px;
                    padding: 0;
                    border: 0;
                    background: transparent;
                    border-radius: 3px;
                    display: flex;
                    justify-content: center;
                    align-items: center;

                    &.active {
                      background: #E9E8F7;
                    }
                  }

                  .form-setting{
                    position: absolute;
                    right: 155px;
                    bottom: 2px;

                    .form-setting-header {
                      button {
                        border: 0;
                        padding: 0;
                        background: transparent;
                      }
                    }
                  }
                }
              }

              .table-sensor-custom-mb{
                width: 100%;
                max-height: 500px;
                overflow: auto;
                padding: 10px 12px;
                display:flex;
                flex-direction:column;
                gap: 10px;

                .no-sensor-mb{
                  width: 100%;
                  text-align: center;
                  color: #AAA3A2;
                  font: 700 12px Inter;
                  line-height: 15px; /* 125% */
                  margin: 0;
                }

                .table-sensor{
                  width: 100%;
                  padding: 16px 20px;
                  border-radius: 6px;
                  border: 1px solid #CAC5C4;
                  background: #FFF;

                  .header{
                    width: 100%;
                    display: flex;
                    align-items: center;
                    justify-content: space-between;
                    padding-bottom: 7px;
                    border-bottom: 1px solid $dark_neutral;

                    strong {
                      color: $dark_neutral;
                      font: 700 16px Inter;
                    }

                    span {
                      color: $dark_neutral;
                      font: 400 13px Inter;
                    }
                  }

                  .body {
                    width: 100%;
                    display: flex;
                    flex-direction: column;

                    .row-item {
                      display: flex;
                      height: 30px;
                      padding: 5px 9px;
                      justify-content: space-between;
                      align-items: center;
                      align-self: stretch;
                      border-bottom: 1px solid #D9D9D9;
                      gap: 5px;

                      p{
                        margin: 0;
                        overflow: hidden;
                        color: $dark_neutral;
                        text-overflow: ellipsis;
                        white-space: nowrap;
                        font-family: Inter;
                        font-size: 15px;
                        font-style: normal;
                        font-weight: 500;
                        line-height: 18px; /* 120% */
                        max-width: 65%;
                      }

                      span {
                        max-width: 35%;
                        overflow: hidden;
                        color: $dark_neutral;
                        text-align: right;
                        text-overflow: ellipsis;
                        white-space: nowrap;
                        font-family: Inter;
                        font-size: 15px;
                        font-style: normal;
                        font-weight: 400;
                        line-height: 18px; /* 120% */
                      }
                    }
                  }
                }
              }
            }

            .images-wrapper{
              .image-header{
                padding: 0 10px;
                justify-content: flex-end;
              }

              .image-body{
                max-height: 365px;
                gap: 0;
                width: 100%;
                margin-left: 0;
                padding: 0;

                .image-item{
                  width: 100%;
                  padding: 8px 20px 8px 16px;
                  flex-direction: row;
                  gap: 10px;
                  align-self: stretch;
                  background-color: $white;
                  border: 0;
                  border-radius: 0;

                  img {
                    width: 50px;
                    height: 50px;
                    border-radius: 3px;
                    object-fit: cover;
                    flex-shrink: 0;
                  }

                  .image-item-info {
                    align-items: flex-start;
                    flex: 1;
                    overflow: hidden;
                    
                    .image-name{
                      color: $dark_neutral;
                      font: 400 12px Inter;
                      line-height: 15px; /* 125% */
                      align-self: stretch;
                      text-align: start;
                      display:-webkit-box;
                      -webkit-line-clamp:2;
                      -webkit-box-orient: vertical;
                      overflow: hidden;
                      text-overflow: ellipsis;
                      word-break: break-word;
                    }
                  }
                }
              }

              .no-img{
                padding: 10px;
              }
            }
          }
        }
      }
    }
  }
}

.modal-setting-dataset.chart {
  display: flex;
  flex-direction: column;
  position: relative;
  padding: 30px 36px 24px;
  gap: 26px;

  .close {
    position: absolute;
    top: 14px;
    right: 16px;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0;
    border: 0;
    background: transparent;
    
    svg {
      width: 20px;
      height: 20px;
      flex-shrink: 0;
    }
  }

  .ok {
    border: 0;
    background: transparent;
    padding: 0;
    border-radius: 3px;
    width: 100px;
    height: 32px;
    flex-shrink: 0;
    background: #5F55C4;
    color: rgba(255, 255, 255, 0.80);
    text-align: center;
    font-size: 13px;
    font-weight: 400;
    line-height: 20px; /* 153.846% */
    margin: auto;
  }

  & > div.legend{
    padding-left: 0;

    .legend-list{
      gap: 16px;

      .legend-item {
        width: 100%;

        label {
          display: flex;
          width: 100%;
          align-items: center;
        }
      }
    }
  }
}

.images-view-detail-mb{
  padding-top: 68px;
  overflow: hidden;

  .detail-img-mb{
    padding: 0;
    height: 100%;
    overflow: hidden;
    display: flex;
    flex-direction: column;

    .detail-img-mb-header {
      display: flex;
      height: 40px;
      padding: 0px 12px;
      justify-content: space-between;
      align-items: center;
      align-self: stretch;

      p{
        margin: 0;
        color: #000;
        font: 400 12px Inter;
        line-height: 14px; /* 116.667% */
      }

      button {
        background: transparent;
        border: 0;
        padding: 0;
        display: flex;
        justify-content: center;
        align-items: center;
        cursor: pointer;

        svg {
          flex-shrink: 0;
          width: 13px;
          height: 13px;
        }
      }
    }

    & > img {
      height: 200px;
      object-fit: contain;
    }

    .img-name{
      margin: 10px 0;
      padding: 0 10px;
      color: $dark_neutral;
      font: 400 12px Inter;
      line-height: 15px;
      display:-webkit-box;
      -webkit-line-clamp:1;
      -webkit-box-orient: vertical;
      overflow: hidden;
      text-overflow: ellipsis;
      word-break: break-word;
    }

    .detail-img-mb-list {
      width: 100%;
      display: flex;
      flex-direction: column;
      flex: 1;
      overflow-y: auto;

      .img-view-item {
        display: flex;
        padding: 8px 20px 8px 16px;
        align-items: center;
        gap: 10px;
        align-self: stretch;

        & > img {
          width: 50px;
          height: 50px;
          object-fit: cover;
          border-radius: 3px;
          flex-shrink: 0;
        }

        .img-view-item-info {
          display: flex;
          flex-direction: column;
          align-items: flex-start;
          gap: 5px;
          flex: 1 0 0;

          span {
            color: $dark_neutral;
            font-weight: 400;
            font-family: Inter;
            line-height: 15px;

            &:first-child {
              font-size: 12px;
              display:-webkit-box;
              -webkit-line-clamp:2;
              -webkit-box-orient: vertical;
              overflow: hidden;
              text-overflow: ellipsis;
              word-break: break-word;
            }

            &:last-child {
              font-size: 10px;
            }
          }
        }

        &.active {
          background: #E9E8F7;
        }
      }
    }
  }
}