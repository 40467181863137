.drone-home{
  overflow-y: scroll;

  &::-webkit-scrollbar{
    display: none;
  }

  & > .dps-content-area{
    .dps-title{
      background-color: $background;
      position: sticky;
      top: 0;
      padding: 20px 0;
      min-height: unset;
      max-height: unset;
      z-index: 5;
      margin-bottom: 0;
    }


    .dps-main-content{
      overflow: unset;
      flex: 1;

      .drone-status-area, .drone-selector{
        display: flex;
        flex-direction: column;
  
        .drone-introduce{
          font-size: 24px;
          font-weight: normal;
          padding: 34px 0;
          text-align: center;
          background-color: #3E3A3940;
          background-image: url("../images/bg.6570f60f.png");
          background-size: cover;
          background-position: center;
          margin-bottom: 27px;
          border-radius: 10px;
          display: flex;
          justify-content: center;
          align-items: center;
          flex: 1;
  
          b{
            font-family: 'Inter', sans-serif;
            color: #000000;
            text-shadow: 0 0 12px #ffffffe8;
            margin-right: 35px;
          }
          span{
            font: 300 24px 'Inter', sans-serif;
          }
        }
  
        @media (max-width: 950px) {
          .drone-introduce{
            flex: unset;
            height: 180px;
          }
  
        }
  
        .preview-group{
          .preview-content td{
            padding: 20px;
  
            &:first-child{
              width: auto;
              font: 700 14px 'Inter', sans-serif;
            }
            &:last-child{
              font: 400 14px 'Inter', sans-serif;
            }
          }        
        }
  
        .drone-preview{
          padding-bottom: 20px;

          h4{
            font: 700 16px 'Inter', sans-serif;
            margin-bottom: 0;
            margin-left: 3px;
          }
  
          .drone-list{
            width: calc(100% + 30px);
            overflow-x: auto;
            padding-top: 8px;
            padding-bottom: 10px;
  
            &::-webkit-scrollbar{
              height: 6px;
            }
            &::-webkit-scrollbar-track {
              background-color: #ffffff00;
            }
            &::-webkit-scrollbar-thumb {
              background-color: #00000040;
              border-radius: 3px;
            }
  
            .scroll-x{
              width: max-content;
              display: flex;

              .device-item{
                margin-right: 15px;

                &:last-child{
                  margin-right: 30px;
                }
              }
            }
          }
        }
      }
    }
  }
}

// Mobile
.dps-mobile-view .drone-home{
  .preview-group {
    display: block;
    margin: 0;

    .preview-item {
      width: 100%;

      .preview-item-right {
        margin-top: 28px;
      }
    }
  }
}