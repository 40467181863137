.notification-container{
  top: unset;
  bottom: 0;

  .notification{
    margin-bottom: 15px;
    padding: 14px;
    padding-top: 30px;
    border-radius: 5px;
    border-left: 4px solid;
    color: $dark_neutral;
    position: relative;
    font-size: 14px;

    &::before{
      content: '';
      font-weight: bold;
      font-size: 18px;
      text-transform: capitalize;
      top: 14px
    }

    &::after{
      content: '\D7';
      width: 18px;
      height: 18px;
      display: flex;
      justify-content: center;
      align-items: center;
      font-weight: bold;
      font-size: 20px;
      border-radius: 100rem;
      position: absolute;
      top: 14px;
      right: 14px;
    }

    &:hover::after{
      transition: 0.25s linear;
    }
  }

  .notification-info{
    background: $info-100;
    border-color: $info;

    &::before{
      content: 'info';
    }
    &::after{
      color: $info;
    }
    &:hover::after{
      background-color: $info-250 !important;
    }
  }
  .notification-success{
    background: $success-100;
    border-color: $success;

    &::before{
      content: 'Success';
    }
    &::after{
      color: $success;
    }
    &:hover::after{
      background-color: $success-250 !important;
    }
  }
  .notification-warning{
    background: $warning-100;
    border-color: $warning;

    &::before{
      content: 'Warning';
    }
    &::after{
      color: $warning;
    }
    &:hover::after{
      background-color: $warning-250 !important;
    }
  }
  .notification-error{
    background: $danger-100;
    border-color: $danger;

    &::before{
      content: 'Error';
    }
    &::after{
      color: $danger;
    }
    &:hover::after{
      background-color: $danger-250 !important;
    }
  }
}