#root .dps-mobile-view {
  .dps-menu {
    display: none;
  }

  .dps-content{
    width: 100%;
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    overflow: hidden;
    padding: 0;
    overflow: hidden;

    .dps-content-area{
      width: 100%;
      height: 100%;
      min-width: unset;
      padding-top: 68px;
      overflow: hidden;
      
      .dps-main-content {
        padding: 20px;
        overflow: hidden;

        .dps-main-content-area {
          overflow-x: hidden;
          overflow-y: auto;
        }
      }
    }
  }
}

.dps-mobile-view {
  .mobile-menu {
    background-color: white;
    position: fixed;
    bottom: 0;
    width: 100%;
    height: 50px;
    z-index: 1000;

    .mobile-menu-item {
      padding: 8px 5px;
      width: 25%;
      float: left;
      display: inline-block;

      a {
        flex-direction: column;
        align-items: center;
        justify-content: center;
        display: flex;

        text-decoration: none;

        svg,
        span {
          color: $c-black;
          fill: $c-black;
        }

        span {
          width: 100%;
          text-align: center;
          font-size: 10px;
          margin-top: 5px;
          text-decoration: none;
        }
      }
    }
  }

  .m-selector-area {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding: 10px;
    align-items: center;
    margin-bottom: 0px;

    .m-selector {
      background-color: $c-yellow;
      border-radius: 10px;
      height: 50px;
      padding: 10px 10px;
      font-size: 14px;
      line-height: 30px;
    }

    .toggle {
      margin-left: auto;
    }
  }

  .m-popup-area {
    position: fixed;
    width: 100%;
    height: 100%;
    background-color: rgba($color: #000000, $alpha: 0.5);
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    z-index: 9999;
    padding: 30px;
  }

  .m-popup-result {
    .item {
      flex-direction: column;
      align-items: stretch !important;
    }
  }

  .m-popup-header {
    position: relative;
  }

  .m-popup-close {
    height: 30px;
    width: 30px;
    background-color: $c-red;
    border: 0;
    border-radius: 20px;
    color: white;
    position: absolute;
    top: -5px;
    right: 0px;
  }

  .m-popup-content {
    width: 100%;
    height: 100%;
    background-color: white;
    border-radius: 10px;
    padding: 20px;
    overflow-y: auto;

    h1 {
      font-size: 14px;
      margin-bottom: 20px;
    }

    .item {
      height: 50px;
      background-color: rgba($color: #707070, $alpha: 0.1);
      margin-bottom: 10px;
      border-radius: 10px;
      padding: 5px 15px;
      display: flex;
      align-items: center;

      span {
        font-size: 13px;
        line-height: 40px;
      }

      .border-right {
        border-right: 1px solid rgba($color: #707070, $alpha: 0.2);
        margin-right: 10px;
        padding-right: 10px;
      }

      .delete {
        background-color: $c-red;
        border: 0;
        border-radius: 20px;
        width: 30px;
        height: 30px;
        margin-left: auto;
      }
    }
  }

  .inspection-status-area {
    .m-popup-content {
      .item {
        height: auto;

        .drone-item {
          display: flex;
          flex-direction: row;
          align-items: center;
          padding: 5px 0;
        }

        .result-sort,
        .result-delete {
          width: 30px;
          height: 30px;
          border: 0;
          display: flex;
          align-items: center;
          justify-content: center;
          border-radius: 15px;
        }

        .result-edit {
          width: 30px;
          height: 30px;
          border: 0;
          display: flex;
          align-items: center;
          justify-content: center;
          border-radius: 15px;
        }

        .result-sort {
          margin-left: auto;
          background-color: $c-green;
        }

        .result-delete {
          margin-left: 20px;
          background-color: $c-red;
        }

        .result-edit {
          margin-left: 20px;
          background-color: $c-black;
        }
      }

      .active {
        background-color: $c-yellow;

        .drone-item {
          border-bottom: 1px solid rgba($color: #707070, $alpha: 0.3);
        }
      }
    }
  }

  .card {
    width: 300px;
    height: 450px;
    padding: 20px;
  }
}

.image-preview-mobile {
  display: block;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 9999;

  .modal-content {
    width: 90%;
    height: 90%;
    position: relative;

  }

  .modal-body {
    height: 100%;
    width: 100%;
    padding: 0;

    .gallery {
      width: 100%;
      height: 100%;
      display: flex;
      flex-direction: column;

      .img-area {
        flex: 1;

        .img-preview {
          width: 100%;
          height: 100%;
          background-size: contain;
          background-repeat: no-repeat;
          background-position: center center;
        }
      }

      .gallery-slide {
        height: 80px;
        overflow-x: auto;
        margin: 0 auto;

        .img-item {
          width: 60px;
          display: inline-block;
          margin: 10px 5px 0 5px;
          height: 60px;
          background-size: cover;
          background-repeat: no-repeat;
        }
      }
    }
  }

  .modal-header {
    position: absolute;
    right: 5px;
    border: 0px;
    top: 5px;
    padding: 0;
    height: auto;
  }
}

.wrapper-scan-btn-mobile {
 
  display: none;

}

.title-scan-mobile {
  display: none ;
}

.form-input-and-image-mobile {
  display: none;
}

// .dps-mobile-view {
@media (max-width: 845px ) {
  .dps-input-mobile2 {
    color: #808080;
    height: 48px;
    width: 127px;
    border-radius: 8px;
    border: 0px;
    background: #f8f8f8 !important;
    display: flex !important;
    justify-content: flex-start !important;
    outline: none;
    padding: 0 20px;
  }

  .dps-input-mobile1 {
    color: #808080;
    padding: 10px 20px;
    height: 48px;
    width: 330px;
    border-radius: 3px;
    border: 0px;
    background: #f8f8f8 !important;
    border: 1px solid #EBEBEB;
  }

  .wrapper-input-and-image {
    div {
      // flex: none !important;
      display: flex !important;
      justify-content: space-between !important;
    }
  }
}