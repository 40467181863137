.panel-wrap{
  --gap: 0px;
  --height-handle: 6px;
  width: 100%;
  height: 100%;
  overflow: hidden;
  display: grid;
  gap: var(--gap);
  grid-template-columns: 1fr 1fr;
  grid-template-rows: 
    calc((1% * var(--main)) - var(--gap) - (var(--height-handle) / 2))
    var(--height-handle)
    calc((1% * var(--sub)) - var(--gap) - (var(--height-handle) / 2))
  ;
  position: relative;

  .panel {
    overflow: hidden;
  }

  .resize-handle {
    height: var(--height-handle);
    grid-column-start: 1;
    grid-column-end: 3;
    grid-row-start: 2;
    grid-row-end: 3;
    cursor: ns-resize;

    &.panel-resize-handle{
      width: 16.8px;
      display: flex;
      align-self: center;
      align-items: center;
      margin: auto;
      
      &::before {
        content: '';
        width: 3.6px;
        height: 3.6px;
        border-radius: 100rem;
        background: #A6A6A6;
        box-shadow: 6.6px 0 0 0 #A6A6A6, 13.2px 0 0 0 #A6A6A6;
      }
    }
  }

  .main{
    grid-column-start: 1;
    grid-column-end: 3;
    grid-row-start: 1;
    grid-row-end: 2;
    order: 1;

    padding-right: 49px;

    &:has(.streaming) {
      padding-right: 48px;

      &.main-full-screen {
        padding-right: 49px
      }
    }
  }
  
  .sub{
    order: 0;
    grid-row-start: 3;
    grid-row-end: 4;
    width: calc(100% - 3px);

    &.sub-l{
      grid-column-start: 1;
      grid-column-end: 2;
    }
    
    &.sub-r{
      grid-column-start: 2;
      grid-column-end: 3;
      margin-left: auto;
    }
  }

  .main-actions {
    height: calc((1% * var(--main)) - var(--gap) - (var(--height-handle) / 2));
    overflow: hidden;
  }
}