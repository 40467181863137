.setting-page{
  .actions {
    gap: 10px;

    .btn-small {
      width: max-content !important;
      height: max-content !important;
      padding: 0;
      margin: 0 !important;
      background: transparent !important;
      padding: 3px;


      &:hover {
        background: #bebaff !important;
      }
  
      .fix-icon-center-ios{
        position: static;
        transform: unset;
        
        svg {
          width: auto !important;
        }
      }
    }
  }

  .add-charging-station{
    width: 100%;
    padding: 20px 15px;
    display: flex;
    align-items: center;
    gap: 10px;
    margin-bottom: 14px;

    label {
      font: 400 14px Inter;
      color: #000;
    }
    
    input {
      width: 200px;
      height: 32px;
      padding-inline: 10px;
      border: 1px solid #7168CA;
      border-radius: 3px;
      background: #fff;
      font: 400 14px Inter;
      color: #000;
      line-height: 32px;
    }

    .btn-save{
      width: 70px;
      height: 32px;
      display: flex;
      justify-content: center;
      align-items: center;
      border-radius: 3px;
      border: 0;
      background: #5F55C4;
      font: 600 12px Inter;
      color: #fff;
    }
  }
}

// setting form modal
.setting-form {
  .setting-form-dialog{
    max-width: 270px;

    .modal-body {
      border: 1px solid #A6A0DE;
      border-radius: 4px;
      background: #fff;
      padding: 35px 16px 16px 18px;
      position: relative;
  
      & > .btn-close{
        position: absolute;
        top: 11px;
        right: 13px;
        width: 13px;
        height: 13px;
        padding: 0;
      }

      .edit-type{
        display: flex;
        flex-direction: column;
        gap: 14px;

        .item{
          width: 100%;
          height: 30px;
          display: flex;
          align-items: center;
          justify-content: space-between;
          gap: 8px;
          position: relative;

          label {
            font: 400 11px Inter;
            color: $dark_neutral;
          }
          
          input, .mash_input {
            width: 114px;
            height: 28px;
            border: 1px solid #7168CA;
            border-radius: 3px;
            padding-inline: 10px;
            background: #fff;
            font: 400 11px Inter;
            color: $dark_neutral;
            line-height: 28px;
            display:-webkit-box;
            -webkit-line-clamp:1;
            -webkit-box-orient: vertical;
            overflow: hidden;
            text-overflow: ellipsis;
            word-break: break-word;

            &::-webkit-outer-spin-button,
            &::-webkit-inner-spin-button {
              -webkit-appearance: none;
              margin: 0;
            }

            &[type=number] {
              text-align: right;
            }

            &.grow-1{
              flex: 1;
            }
          }

          input:disabled, .mash_input {
            background: $light;
          }

          .dps-select{
            width: 125px;

            select {
              text-align: center;
            }
          }

          .icon_folder{
            padding: 0;
            margin: 0;
            background: transparent;
            border: 0;
          }

          .err_mes {
            position: absolute;
            top: 100%;
            right: 0;
            font: 400 10px Inter; 
            color: $danger;
          }
        }

        .actions {
          width: 100%;
          margin-top: 6px;
          display: flex;
          justify-content: space-between;
          align-items: center;

          .btn-delete {
            background: transparent;
            margin: 0;
            padding: 0;
            border: 0;
          }

          .btn-save{
            width: 90px;
            height: 32px;
            display: flex;
            justify-content: center;
            align-items: center;
            margin-left: auto;
            background: #5F55C4;
            border-radius: 3px;
            font: 600 12px Inter;
            color: #fff;

            &:disabled {
              opacity: 0.4;
              cursor: default;
            }
          }
        }
      }
    }
  }
}